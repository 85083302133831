import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
	selector: "app-two-button-zero-input",
	templateUrl: "./two-button-zero-input.component.html",
	styleUrls: ["./two-button-zero-input.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class TwoButtonZeroInputComponent {
	content = {
		title: "",
		text: "",
		buttonLeft: "",
		buttonRight: ""
	};

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<TwoButtonZeroInputComponent>) {
		this.content.title = data.title ? data.title : "";
		this.content.text = data.text ? data.text : "";
		this.content.buttonLeft = data.buttonLeft ? data.buttonLeft : "Cancelar";
		this.content.buttonRight = data.buttonRight ? data.buttonRight : "Confirmar";
	}

	close(value: boolean) {
		this.dialogRef.close(value);
	}
}
