import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { API } from "../../app.api";

@Injectable({
	providedIn: "root"
})
export class CompanyService {
	constructor(public http: HttpClient) {}

	// BUSCA SIMPLES COM FILTROS
	getAll(): any {
		return this.http.get(`${API}/companies`);
	}

	// BUSCA POR ID
	getById(id) {
		return this.http.get(`${API}/companies`, {
			params: new HttpParams().set("_id", id)
		});
	}

	// BUSCA COM PAGINAÇÃO E FILTROS
	listCompanies(filter: any) {
		return this.http.get(`${API}/companies/list`, {
			params: new HttpParams().set("filter", filter.filter).set("page", filter.page).set("limit", filter.limit)
		});
	}

	getByCnpj(cnpj: string) {
		return this.http.get(`${API}/companies/cnpj`, {
			params: new HttpParams().set("cnpj", cnpj)
		});
	}

	// CADASTRA UMA EMPRESA
	create(body: any) {
		return this.http.post(`${API}/companies`, body);
	}

	getCompanyPCL() {
		return this.http.get(`${API}/companies/pcl`);
	}

	update(_id, company) {
		return this.http.put(`${API}/companies/${_id}`, { company });
	}
}
