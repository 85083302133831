import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

////////////////////////////
/// jsonToCsv parameters ///
////////////////////////////

/* Parameter columns
	columns = [
		{
			name: 'Donor',
			propertie: 'donor'
		},
		{
			name: 'Laboratory',
			propertie: 'lab'
		}
	];
*/

/* Parameter data
	data = [
		{
			donor: 'Joseph',
			lab: 'Asgard'
		},
				{
			donor: 'Hanson',
			lab: 'Iron Man'
		},
	];
*/

@Injectable({
	providedIn: "root"
})
export class CsvService {
	fileReader: FileReader;

	constructor(public http: HttpClient) {
		this.fileReader = new FileReader();
	}

	csvToJson(columns: any[], file: any): Observable<any> {
		return Observable.create((observer: any) => {
			this.readAsText(file, (errors: any, res: any) => {
				if (errors) {
					console.log(errors);
				} else {
					let endHeaderIndex: number;
					let csvContent: any;
					let csvConverted: any = new Array();
					let lineJson: any = new Object();
					let colValue: string = "";
					let colIndex: number = 0;
					res = res.split("");

					// Gets the last char index from the header line of the file
					res.find((elem: string, index: number) => {
						endHeaderIndex = index + 1;
						return elem == "\n";
					});
					// Removes the header line of the file(Column titles)
					csvContent = res.slice(endHeaderIndex, res.length - 1);

					csvContent.push("\n");

					csvContent.forEach((elem: any, index: number) => {
						// Check if it is a line ending
						if (elem != ";" && elem != "\n") {
							colValue += elem;
						} else {
							if (columns[colIndex]) {
								lineJson[columns[colIndex]] = colValue;
							}
							colValue = "";

							if (colIndex + 1 == columns.length) {
								csvConverted.push(lineJson);
								colIndex = 0;
								lineJson = new Object();
							} else {
								colIndex++;
							}
						}
					});

					observer.next(csvConverted);
				}
			});
		});
	}

	// Read the file as text
	private readAsText(file: any, callback: any): void {
		if (file) {
			this.fileReader.readAsText(file, "\ufeff");
		}

		this.fileReader.onload = (e: any) => {
			callback(null, e.target.result);
		};
	}

	// Convert a JSON to CSV
	jsonToCsv(columns: any[], data: any[]): Observable<any> {
		return Observable.create((observer: any) => {
			let csv: string = "";
			
			// Create the columns
			columns.forEach((col: any, index: number) => {
				if (index == 0) csv += col.name;
				else csv += ";" + col.name;
			});

			// Create the lines
			data.forEach((obj: any, index: number) => {
				csv += "\n"; // Create the new line

				// Create a cell for each column
				columns.forEach((col: any, index: number) => {
					if (index == 0) {
						csv += obj[col.propertie];
					} else {
						csv += ";" + obj[col.propertie];
					}
				});
			});

			observer.next(csv);
		});
	}

	// Download CSV
	// This method just work in browsers
	download(csv: string, name: string) {
		let blob = new Blob(["\ufeff", csv], { type: "text/plain" });
		let url = URL.createObjectURL(blob);

		// Simulate a click for initiate a download
		let a = document.createElement("a");
		a.download = name + ".csv";
		a.href = url;
		a.textContent = "Download " + name + ".csv";
		a.click();
	}
}
