import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { FormControl } from "@angular/forms";
import { startWith, map } from "rxjs/operators";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { CompanyService } from "src/app/services/company/company.service";
import { Observable } from "rxjs";
import { PaymentService } from "src/app/services/payment/payment.service";

@Component({
	selector: "app-financial-payments",
	templateUrl: "./payments.component.html",
	styleUrls: ["./payments.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class FinancialPaymentsComponent implements OnInit {
	columns = ["cnpj", "legalName", "price", "link", "reason"];
	dataSource = new MatTableDataSource();
	filter: FormControl = new FormControl("");

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	filteredPJs: Observable<any[]>;
	length = 0;
	pj: any;
	pjSearchbar: FormControl = new FormControl();
	pjs = [];

	constructor(private companyService: CompanyService, private paymentService: PaymentService, private loaderService: LoaderService) {
		this.getCompanies();
	}

	ngOnInit() {
		this.loadData();
		this.createObservables();
	}

	createObservables() {
		this.filteredPJs = this.pjSearchbar.valueChanges.pipe(
			startWith(""),
			map((value: any) => (typeof value === "string" ? value : value.legalName)),
			map((name) => (name ? this.filterPJs(name) : this.pjs.slice()))
		);
		this.paginator.page.subscribe(() => this.loadData());
	}

	filterTable() {
		this.dataSource.filter = this.filter.value.trim();
	}

	getData() {
		this.paginator.pageIndex = 0;
		this.loadData();
	}

	loadData() {
		const query: any = {
			page: this.paginator.pageIndex,
			limit: this.paginator.pageSize ? this.paginator.pageSize : 10
		};
		if (this.pj && this.pj.type == "company") {
			query._company = this.pj._id;
		} else if (this.pj) {
			query._pcl = this.pj._id;
		}

		this.loaderService.newLoader("Buscando Postos de Coleta");
		this.paymentService.listPayments(query).subscribe((response: any) => {
			this.length = response.data.count;
			this.dataSource.data = response.data.payments;
			this.loaderService.stopLoader();
		});
	}

	getCompanies() {
		this.companyService.getCompanyPCL().subscribe((res: any) => {
			this.pjs = res.data;
		});
	}

	displayFnPJ(pj?: any): string | undefined {
		return pj ? pj.legalName : undefined;
	}

	filterPJs(value: any) {
		value = value.replace(/\.|\-|\//g, "");

		return this.pjs.filter((pj) => pj.legalName.toLowerCase().indexOf(value.toLowerCase()) === 0 || pj.cnpj.toLowerCase().indexOf(value.toLowerCase()) === 0);
	}

	selectedPJ(e) {
		this.pj = e.option.value;
		this.getData();
	}
}
