import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { API } from "../../app.api";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
	providedIn: "root"
})
export class UserService {
	constructor(public http: HttpClient, private router: Router) {}

	create(body: any): Observable<any> {
		return this.http.post(`${API}/users`, body);
	}

	async forgotPassword(body: { email: string; api: string }): Promise<Observable<any>> {
		const forgot_password = this.http.put(`${API}/users`, body);
		await forgot_password.forEach((element) => {
			if (element["error"]) throw new Error(element["error"]);
		});

		return forgot_password;
	}

	resetPassword(params: { jwtToken: string; newPassword: string }): Observable<any> {
		var reset_password;
		this.http.put(`${API}/users/resetpassword/` + params.jwtToken, { newPassword: params.newPassword }).subscribe(
			(value) => {
				reset_password = value;

				if (value["error"]) {
					alert(value["error"]["name"] + "\n" + value["error"]["message"]);
					console.log(value["error"]["name"]);
				} else {
					this.router.navigate(["/login"]);
					alert("Senha alterada com sucesso!");
					this.router.navigate(["/"]);
				}
			},
			(err) => {
				console.log("HTTP Error", err);
			}
		);
		return reset_password;
	}

	getAll() {
		return this.http.get(`${API}/users`);
	}

	// BUSCA COM PAGINAÇÃO E FILTROS
	listUsers(filter: any) {
		return this.http.get(`${API}/users/list`, {
			params: new HttpParams().set("filter", filter.filter).set("page", filter.page).set("limit", filter.limit)
		});
	}

	createPermission(body: any): Observable<any> {
		return this.http.post(`${API}/permissions`, body);
	}

	getAllPermissions() {
		return this.http.get(`${API}/permissions`);
	}

	listPermissions(filter: any) {
		return this.http.get(`${API}/permissions/list`, {
			params: new HttpParams().set("filter", filter.filter).set("page", filter.page).set("limit", filter.limit)
		});
	}
}
