import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class UtilsService {
	constructor(public http: HttpClient) {}

	validateCpf(control: FormControl) {
		// tslint:disable-next-line:prefer-const
		let cpf = control.value.replace(/[^\d]+/g, "");
		let numbers, digits, sum, i, result, mututalDigits;
		mututalDigits = 1;
		if (cpf.length < 11) return { invalidCpf: true };
		for (i = 0; i < cpf.length - 1; i++)
			if (cpf.charAt(i) != cpf.charAt(i + 1)) {
				mututalDigits = 0;
				break;
			}
		if (!mututalDigits) {
			numbers = cpf.substring(0, 9);
			digits = cpf.substring(9);
			sum = 0;
			for (i = 10; i > 1; i--) sum += numbers.charAt(10 - i) * i;
			result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
			if (result != digits.charAt(0)) return { invalidCpf: true };
			numbers = cpf.substring(0, 10);
			sum = 0;
			for (i = 11; i > 1; i--) sum += numbers.charAt(11 - i) * i;
			result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
			if (result != digits.charAt(1)) return { invalidCpf: true };
			return null;
		} else return { invalidCpf: true };
	}

	validateCnpj(control: FormControl) {
		if (control.value != null) {
			const cnpj = control.value.replace(/[^\d]+/g, "");

			if (cnpj == "") return { invalidCnpj: true };

			if (cnpj.length != 14) return { invalidCnpj: true };

			// Elimina CNPJs invalidos conhecidos
			if (
				cnpj == "00000000000000" ||
				cnpj == "11111111111111" ||
				cnpj == "22222222222222" ||
				cnpj == "33333333333333" ||
				cnpj == "44444444444444" ||
				cnpj == "55555555555555" ||
				cnpj == "66666666666666" ||
				cnpj == "77777777777777" ||
				cnpj == "88888888888888" ||
				cnpj == "99999999999999"
			)
				return { invalidCnpj: true };

			// Valida DVs
			let size = cnpj.length - 2;
			let numbers = cnpj.substring(0, size);
			const digits = cnpj.substring(size);
			let sum = 0;
			let pos = size - 7;
			for (let i = size; i >= 1; i--) {
				sum += numbers.charAt(size - i) * pos--;
				if (pos < 2) pos = 9;
			}
			let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
			if (resultado != digits.charAt(0)) return { invalidCnpj: true };

			size = size + 1;
			numbers = cnpj.substring(0, size);
			sum = 0;
			pos = size - 7;
			for (let i = size; i >= 1; i--) {
				sum += numbers.charAt(size - i) * pos--;
				if (pos < 2) pos = 9;
			}
			resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
			if (resultado != digits.charAt(1)) return { invalidCnpj: true };

			return null;
		}
	}

	validateCnpjOrNull(control: FormControl) {
		if (control.value != null) {
			if (control.value == "") return null;
			const cnpj = control.value.replace(/[^\d]+/g, "");

			if (cnpj == "") return { invalidCnpj: true };

			if (cnpj.length != 14) return { invalidCnpj: true };

			// Eliminates Invalid known CNPJs
			if (
				cnpj == "00000000000000" ||
				cnpj == "11111111111111" ||
				cnpj == "22222222222222" ||
				cnpj == "33333333333333" ||
				cnpj == "44444444444444" ||
				cnpj == "55555555555555" ||
				cnpj == "66666666666666" ||
				cnpj == "77777777777777" ||
				cnpj == "88888888888888" ||
				cnpj == "99999999999999"
			)
				return { invalidCnpj: true };

			// Valida DVs
			let size = cnpj.length - 2;
			let numbers = cnpj.substring(0, size);
			const digits = cnpj.substring(size);
			let sum = 0;
			let pos = size - 7;
			for (let i = size; i >= 1; i--) {
				sum += numbers.charAt(size - i) * pos--;
				if (pos < 2) pos = 9;
			}
			let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
			if (resultado != digits.charAt(0)) return { invalidCnpj: true };

			size = size + 1;
			numbers = cnpj.substring(0, size);
			sum = 0;
			pos = size - 7;
			for (let i = size; i >= 1; i--) {
				sum += numbers.charAt(size - i) * pos--;
				if (pos < 2) pos = 9;
			}
			resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
			if (resultado != digits.charAt(1)) return { invalidCnpj: true };

			return null;
		} else return null;
	}

	getCEP(cep: string): Observable<any> {
		return this.http.get(`https://viacep.com.br/ws/${cep}/json`);
	}

	getStates(): Observable<any> {
		return this.http.get("../assets/json/states.json");
	}

	getCities(): Observable<any> {
		return this.http.get("../assets/json/cities.json");
	}

	getCPF(cpf) {
		return this.http.get(`https://ctox-api-666cipy5da-rj.a.run.app/api/v1/cpf/${cpf}`);
	}

	getCNPJ(cnpj) {
		return this.http.jsonp("https://www.receitaws.com.br/v1/cnpj/" + cnpj, "callback");
	}

	removeSpecialCharacters(str: string) {
		const _str = str.split(" ");
		return _str.map((word: string) => _removeSpecialCharacters(word)).join(" ");

		function _removeSpecialCharacters(str: string) {
			str = str.replace(/[ÀÁÂÃÄÅ]/gi, "A");
			str = str.replace(/[àâãäá]/gi, "a");
			str = str.replace(/[ÈÉẼÊË]/gi, "E");
			str = str.replace(/[èêẽëé]/gi, "e");
			str = str.replace(/[ÌÎÏĨÍ]/gi, "I");
			str = str.replace(/[ìîïĩí]/gi, "i");
			str = str.replace(/[ÒÔÕÖÓ]/gi, "O");
			str = str.replace(/[òôöõó]/gi, "o");
			str = str.replace(/[ÙÛŨÜÚ]/gi, "U");
			str = str.replace(/[ùûüũú]/gi, "u");
			str = str.replace(/[Ç]/gi, "C");
			str = str.replace(/[ç]/gi, "c");
			return str.replace(/[^a-z]/gi, "");
		}
	}

	phoneMask(value: any) {
		if (value.length <= 10) return "(00)0000-00009";
		else return "(00)00000-0000";
	}

	// custom validator to check that two fields match
	matchPassword(controlName: string, matchingControlName: string) {
		return (formGroup: FormGroup) => {
			const control = formGroup.controls[controlName];
			const matchingControl = formGroup.controls[matchingControlName];

			if (matchingControl.errors && !matchingControl.errors.mustMatch) {
				// return if another validator has already found an error on the matchingControl
				return;
			}

			// set error on matchingControl if validation fails
			if (control.value !== matchingControl.value) {
				matchingControl.setErrors({ mustMatch: true });
			} else {
				matchingControl.setErrors(null);
			}
		};
	}
}
