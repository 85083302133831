import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import * as moment from "moment";

@Component({
	selector: "app-report-list",
	templateUrl: "./report-list.component.html",
	styleUrls: ["./report-list.component.scss"]
})
export class ReportListComponent {
	@Input() samples: any[];
	@Output() amostra = new EventEmitter<string>();
	columns = ["code", "date", "select"];
	dataSource = new MatTableDataSource();

	constructor() {}

	ngOnInit() {
		this.loadData();
	}

	loadData() {
		this.dataSource.data = this.samples.map((item) => {
			item.sample.gatheringDate = moment(item.sample.gatheringDate).format("DD/MM/YYYY");
			item.receivedAt = item.receivedAt ? moment(item.receivedAt).format("DD/MM/YYYY") : "Aguardando recebimento";
			item.payment.status = item.payment.balance == 0 ? "Pago" : "Pendente";
			return item;
		});
	}

	laudo(id: string) {
		this.amostra.emit(id);
	}
}
