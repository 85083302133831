import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { AnalysisService } from "src/app/services/analysis/analysis.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SelectionModel } from "@angular/cdk/collections";
import { LoaderService } from "src/app/shared/loader/loader.service";

@Component({
	selector: "app-conference",
	templateUrl: "./conference.component.html",
	styleUrls: ["./conference.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class ConferenceComponent implements OnInit {
	columns = ["checkbox", "code", "donor", "result"];
	dataSource = new MatTableDataSource();
	selection = new SelectionModel(true, []);
	selected = [];
	lot = "";

	constructor(private analysisService: AnalysisService, private loaderService: LoaderService, private route: ActivatedRoute, private router: Router) {}

	ngOnInit() {
		this.lot = this.route.snapshot.paramMap.get("lot");
		this.getData();
	}

	async getData() {
		this.loaderService.newLoader();
		this.analysisService.getAllInConference(this.lot).subscribe((response: any[]) => {
			this.loaderService.stopLoader();
			this.dataSource.data = response;
		});
	}

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
			this.selected = [];
		} else {
			this.dataSource.data.forEach((row: any) => {
				this.selection.select(row);
				if (!this.selected.includes(row._id)) this.selected.push(row._id);
			});
		}
	}

	selectionToggle(row) {
		this.selection.toggle(row);
		if (!this.selected.includes(row._id)) this.selected.push(row._id);
		else this.selected.splice(this.selected.indexOf(row._id), 1);
	}

	async save() {
		this.loaderService.newLoader();
		this.analysisService.saveConverence({ samples: this.selected }).subscribe((response: any) => {
			this.loaderService.stopLoader();
			this.router.navigate(["amostra/lotes"]);
		});
	}

	async returnToAnalysis() {
		this.loaderService.newLoader();
		this.analysisService.returnToAnalysis({ samples: this.selected }).subscribe((response: any) => {
			this.loaderService.stopLoader();
			this.router.navigate(["amostra/lotes"]);
		});
	}
}
