import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { GatheringService } from "src/app/services/gathering/gathering.service";

@Component({
	selector: "app-passport-modal",
	templateUrl: "./passport-modal.component.html",
	styleUrls: ["./passport-modal.component.scss"]
})
export class PassportModalComponent implements OnInit {
	passport: FormControl = new FormControl("", [Validators.required]);
	constructor(public dialogRef: MatDialogRef<PassportModalComponent>, private gatheringService: GatheringService, @Inject(MAT_DIALOG_DATA) public data: any) {}

	ngOnInit() {}

	async save() {
		await this.gatheringService.update(this.data._id, { "donor.passport": this.passport.value }).toPromise();
		this.dialogRef.close();
	}
}
