import { Component, OnInit, ViewEncapsulation, ViewChild, ɵConsole } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { GatheringService } from "src/app/services/gathering/gathering.service";
import { Router } from "@angular/router";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { CompanyService } from "src/app/services/company/company.service";
import { Observable } from "rxjs";
import { map, startWith, tap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import * as moment from "moment";
import { PdfService } from "src/app/services/pdf/pdf.service";
import * as converter from "xml-js";
import { AuthService } from "src/app/services/auth/auth.service";
import { CsvService } from "src/app/services/csv/csv.service";

@Component({
	selector: "app-gathering-list",
	templateUrl: "./list.component.html",
	styleUrls: ["./list.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class GatheringListComponent implements OnInit {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	columns = ["code", "status", "donor", "date", "receivedAt", "payment", "analysis"];
	filters: FormGroup;
	dataSource = new MatTableDataSource();
	receptionInitialDateMask: FormControl = new FormControl();
	receptionFinalDateMask: FormControl = new FormControl();
	birthDateMask: FormControl = new FormControl();
	gatheringInitialDateMask: FormControl = new FormControl();
	gatheringFinalDateMask: FormControl = new FormControl();
	resultInitialDateMask: FormControl = new FormControl();
	resultFinalDateMask: FormControl = new FormControl();
	length = 0;
	displayFilter: boolean = false;
	statuses = ["Aguardando pagamento", "Criado", "Em análise", "Em conferência", "Finalizado", "Rejeitado"];
	filteredPJs: Observable<any[]>;
	pj: any;
	pjSearchbar: FormControl = new FormControl();
	pjs = [];
	csvColumns: any[]; //{ name: string; propertie: string; }[];

	constructor(
		private companyService: CompanyService,
		private fb: FormBuilder,
		private gatheringService: GatheringService,
		private loaderService: LoaderService,
		private pdfService: PdfService,
		private router: Router,
		private authService: AuthService,
		private csvService: CsvService
	) {
		this.filters = this.fb.group({
			filter: [""],
			status: [""],
			receptionInitialDate: [""],
			receptionFinalDate: [""],
			birthDate: [""],
			gatheringInitialDate: [""],
			gatheringFinalDate: [""],
			resultInitialDate: [""],
			resultFinalDate: [""]
		});

		this.getCompanies();
	}

	ngOnInit() {
		this.loadData();
		this.createObservables();
	}

	createObservables() {
		this.filter.valueChanges
			.pipe(
				debounceTime(400),
				distinctUntilChanged(),
				tap(() => this.getData())
			)
			.subscribe();
		this.receptionFinalDate.valueChanges
			.pipe(
				debounceTime(1000),
				distinctUntilChanged(),
				tap(() => this.getData())
			)
			.subscribe();
		this.status.valueChanges.subscribe(() => this.getData());
		this.paginator.page.subscribe(() => this.loadData());

		this.receptionInitialDateMask.valueChanges
			.pipe(
				debounceTime(1000),
				distinctUntilChanged(),
				tap(() => this.changeReceptionInitialDate())
			)
			.subscribe();
		this.receptionInitialDate.valueChanges
			.pipe(
				debounceTime(600),
				distinctUntilChanged(),
				tap(() => this.changeReceptionInitialDateMask())
			)
			.subscribe();
		this.receptionFinalDateMask.valueChanges
			.pipe(
				debounceTime(1000),
				distinctUntilChanged(),
				tap(() => this.changeReceptionFinalDate())
			)
			.subscribe();
		this.receptionFinalDate.valueChanges
			.pipe(
				debounceTime(600),
				distinctUntilChanged(),
				tap(() => this.changeReceptionFinalDateMask())
			)
			.subscribe();

		this.birthDateMask.valueChanges
			.pipe(
				debounceTime(1000),
				distinctUntilChanged(),
				tap(() => this.changeBirthDate())
			)
			.subscribe();
		this.birthDate.valueChanges
			.pipe(
				debounceTime(600),
				distinctUntilChanged(),
				tap(() => this.changeBirthDateMask())
			)
			.subscribe();

		this.gatheringInitialDateMask.valueChanges
			.pipe(
				debounceTime(1000),
				distinctUntilChanged(),
				tap(() => this.changeGatheringInitialDate())
			)
			.subscribe();
		this.gatheringInitialDate.valueChanges
			.pipe(
				debounceTime(600),
				distinctUntilChanged(),
				tap(() => this.changeGatheringInitialDateMask())
			)
			.subscribe();
		this.gatheringFinalDateMask.valueChanges
			.pipe(
				debounceTime(1000),
				distinctUntilChanged(),
				tap(() => this.changeGatheringFinalDate())
			)
			.subscribe();
		this.gatheringFinalDate.valueChanges
			.pipe(
				debounceTime(600),
				distinctUntilChanged(),
				tap(() => this.changeGatheringFinalDateMask())
			)
			.subscribe();

		this.resultInitialDateMask.valueChanges
			.pipe(
				debounceTime(1000),
				distinctUntilChanged(),
				tap(() => this.changeResultInitialDate())
			)
			.subscribe();
		this.resultInitialDate.valueChanges
			.pipe(
				debounceTime(600),
				distinctUntilChanged(),
				tap(() => this.changeResultInitialDateMask())
			)
			.subscribe();
		this.resultFinalDateMask.valueChanges
			.pipe(
				debounceTime(1000),
				distinctUntilChanged(),
				tap(() => this.changeResultFinalDate())
			)
			.subscribe();
		this.resultFinalDate.valueChanges
			.pipe(
				debounceTime(600),
				distinctUntilChanged(),
				tap(() => this.changeResultFinalDateMask())
			)
			.subscribe();

		this.filteredPJs = this.pjSearchbar.valueChanges.pipe(
			startWith(""),
			map((value: any) => (typeof value === "string" ? value : value.legalName)),
			map((name) => (name ? this.filterPJs(name) : this.pjs.slice()))
		);
	}

	getData() {
		this.paginator.pageIndex = 0;
		this.loadData();
	}

	loadData() {
		const query = {
			filter: this.filter.value,
			status: this.status.value,
			pj: this.pj ? this.pj._id : "",
			birthDate: this.birthDate.value,
			receptionInitialDate: this.receptionInitialDate.value,
			receptionFinalDate: this.receptionFinalDate.value,
			gatheringInitialDate: this.gatheringInitialDate.value,
			gatheringFinalDate: this.gatheringFinalDate.value,
			resultInitialDate: this.resultInitialDate.value,
			resultFinalDate: this.resultFinalDate.value,
			page: this.paginator.pageIndex,
			limit: this.paginator.pageSize ? this.paginator.pageSize : 10
		};

		this.loaderService.newLoader("Buscando Amostras");

		this.gatheringService.listGatherings(query).subscribe((response: any) => {
			this.length = response.count;
			this.dataSource.data = response.gatherings.map((item) => {
				item.sample.gatheringDate = moment(item.sample.gatheringDate).format("DD/MM/YYYY");
				item.receivedAt = item.receivedAt ? moment(item.receivedAt).format("DD/MM/YYYY - H:mm") : "Aguardando recebimento";
				item.payment.status = item.payment.balance == 0 ? "Pago" : "Pendente";
				return item;
			});
			this.loaderService.stopLoader();
		});
	}

	getCompanies() {
		this.companyService.getCompanyPCL().subscribe((res: any) => {
			this.pjs = res.data;
		});
	}

	openDetails(id: string) {
		this.router.navigate([`/amostra/editar/${id}`]);
	}

	openReport(id: string) {
		const url = this.router.createUrlTree([`/laudo/${id}`]);
		window.open(url.toString(), "_blank");
	}

	openPatientForm(id: string) {
		const url = this.router.createUrlTree([`/paciente/${id}`]);
		window.open(url.toString(), "_blank");
	}

	toggleFilter() {
		this.displayFilter = !this.displayFilter;
	}

	displayFnPJ(pj?: any): string | undefined {
		return pj ? pj.legalName : undefined;
	}

	filterPJs(value: any) {
		value = value.replace(/\.|\-|\//g, "");

		return this.pjs.filter((pj) => pj.legalName.toLowerCase().indexOf(value.toLowerCase()) === 0 || pj.cnpj.toLowerCase().indexOf(value.toLowerCase()) === 0);
	}

	selectedPJ(e) {
		this.pj = e.option.value;
		this.getData();
	}

	clearFilters() {
		this.filter.patchValue("", { emitEvent: false });
		this.status.patchValue("", { emitEvent: false });
		this.receptionInitialDate.patchValue("", { emitEvent: false });
		this.receptionInitialDateMask.patchValue("", { emitEvent: false });
		this.receptionFinalDate.patchValue("", { emitEvent: false });
		this.receptionFinalDateMask.patchValue("", { emitEvent: false });
		this.gatheringInitialDate.patchValue("", { emitEvent: false });
		this.gatheringInitialDateMask.patchValue("", { emitEvent: false });
		this.gatheringFinalDate.patchValue("", { emitEvent: false });
		this.gatheringFinalDateMask.patchValue("", { emitEvent: false });
		this.resultInitialDate.patchValue("", { emitEvent: false });
		this.resultInitialDateMask.patchValue("", { emitEvent: false });
		this.resultFinalDate.patchValue("", { emitEvent: false });
		this.resultFinalDateMask.patchValue("", { emitEvent: false });
		this.pjSearchbar.patchValue("", { emitEvent: false });
		this.pj = "";

		this.getData();
	}

	changeReceptionInitialDate() {
		if (this.receptionInitialDateMask.value) {
			this.receptionInitialDate.patchValue(moment(this.receptionInitialDateMask.value, "DD/MM/YYYY").toDate(), { emitEvent: false });
			this.getData();
		}
	}

	changeReceptionInitialDateMask() {
		const date = this.receptionInitialDate.value;
		let day = date.getDate();
		let month = date.getMonth() + 1;
		const year = date.getFullYear();

		if (day < 10) day = `0${day}`;
		if (month < 10) month = `0${month}`;

		this.receptionInitialDateMask.patchValue(`${day}/${month}/${year}`, { emitEvent: false });
		this.getData();
	}

	changeReceptionFinalDate() {
		if (this.receptionFinalDateMask.value) {
			this.receptionFinalDate.patchValue(moment(this.receptionFinalDateMask.value, "DD/MM/YYYY").toDate(), { emitEvent: false });
			this.getData();
		}
	}

	changeReceptionFinalDateMask() {
		const date = this.receptionFinalDate.value;
		let day = date.getDate();
		let month = date.getMonth() + 1;
		const year = date.getFullYear();

		if (day < 10) day = `0${day}`;
		if (month < 10) month = `0${month}`;

		this.receptionFinalDateMask.patchValue(`${day}/${month}/${year}`, { emitEvent: false });
		this.getData();
	}

	changeBirthDate() {
		if (this.birthDateMask.value) {
			this.birthDate.patchValue(moment(this.birthDateMask.value, "DD/MM/YYYY").toDate(), { emitEvent: false });
			this.getData();
		}
	}

	changeBirthDateMask() {
		const date = this.birthDate.value;
		let day = date.getDate();
		let month = date.getMonth() + 1;
		const year = date.getFullYear();

		if (day < 10) day = `0${day}`;
		if (month < 10) month = `0${month}`;

		this.birthDateMask.patchValue(`${day}/${month}/${year}`, { emitEvent: false });
		this.getData();
	}

	changeGatheringInitialDate() {
		if (this.gatheringInitialDateMask.value) {
			this.gatheringInitialDate.patchValue(moment(this.gatheringInitialDateMask.value, "DD/MM/YYYY").toDate(), { emitEvent: false });
			this.getData();
		}
	}

	changeGatheringInitialDateMask() {
		const date = this.gatheringInitialDate.value;
		let day = date.getDate();
		let month = date.getMonth() + 1;
		const year = date.getFullYear();

		if (day < 10) day = `0${day}`;
		if (month < 10) month = `0${month}`;

		this.gatheringInitialDateMask.patchValue(`${day}/${month}/${year}`, { emitEvent: false });
		this.getData();
	}

	changeGatheringFinalDate() {
		if (this.gatheringFinalDateMask.value) {
			this.gatheringFinalDate.patchValue(moment(this.gatheringFinalDateMask.value, "DD/MM/YYYY").toDate(), { emitEvent: false });
			this.getData();
		}
	}

	changeGatheringFinalDateMask() {
		const date = this.gatheringFinalDate.value;
		let day = date.getDate();
		let month = date.getMonth() + 1;
		const year = date.getFullYear();

		if (day < 10) day = `0${day}`;
		if (month < 10) month = `0${month}`;

		this.gatheringFinalDateMask.patchValue(`${day}/${month}/${year}`, { emitEvent: false });
		this.getData();
	}

	changeResultInitialDate() {
		if (this.resultInitialDateMask.value) {
			this.resultInitialDate.patchValue(moment(this.resultInitialDateMask.value, "DD/MM/YYYY").toDate(), { emitEvent: false });
			this.getData();
		}
	}

	changeResultInitialDateMask() {
		const date = this.resultInitialDate.value;
		let day = date.getDate();
		let month = date.getMonth() + 1;
		const year = date.getFullYear();

		if (day < 10) day = `0${day}`;
		if (month < 10) month = `0${month}`;

		this.resultInitialDateMask.patchValue(`${day}/${month}/${year}`, { emitEvent: false });
		this.getData();
	}

	changeResultFinalDate() {
		if (this.resultFinalDateMask.value) {
			this.resultFinalDate.patchValue(moment(this.resultFinalDateMask.value, "DD/MM/YYYY").toDate(), { emitEvent: false });
			this.getData();
		}
	}

	changeResultFinalDateMask() {
		const date = this.resultFinalDate.value;
		let day = date.getDate();
		let month = date.getMonth() + 1;
		const year = date.getFullYear();

		if (day < 10) day = `0${day}`;
		if (month < 10) month = `0${month}`;

		this.gatheringFinalDateMask.patchValue(`${day}/${month}/${year}`, { emitEvent: false });
		this.getData();
	}

	// async downloadReport(data: any) {
	// 	const pdf = await this.pdfService.reportPDF({ data: [data] });
	// }

	importXML(event) {
		const reader = new FileReader();
		reader.onload = (e: any) => {
			const xml = e.target.result;
			const result1: any = converter.xml2js(xml, { compact: true });
			const { Pedido } = result1.Registro.Lote;
			const requests: any[] = [];
			if (!Array.isArray(Pedido)) {
				requests.push(Pedido);
			} else {
				requests.push(...Pedido);
			}
			const gatherings = requests.map((item) => {
				if (!item.Exame.InformacaoComplementar || !item.Exame.InformacaoComplementar.Valor) {
					alert(`A ordem de serviço: ${item.CodPedLab._text} não possue o codigo SemCovid.`);
					return null;
				}
				return {
					sample: {
						serviceOrder: item.CodPedLab._text,
						gatheringDate: moment(item.Exame.DataColeta._text + " " + item.Exame.HoraColeta._text, "YYYY/MM/DD HH:mm:ss").toDate(),
						code: item.Exame.InformacaoComplementar.Valor._text,
						insideCode: true
					},
					donor: {
						name: item.Paciente.Nome._text,
						passport: item.Paciente.Passaporte ? item.Paciente.Passaporte._text : "",
						birthDate: moment(item.Paciente.DataNasc._text).toDate(),
						gender: item.Paciente.Sexo._text === "Feminino" ? "f" : "m"
					}
				};
			});

			this.createGatherings(gatherings);
		};
		reader.readAsText(event.target.files[0]);
	}

	async createGatherings(gatherings) {
		if (gatherings.length > 0) {
			let i = 1;

			for (const gathering of gatherings) {
				gathering._pcl = this.authService.getUser()._pcl;
				gathering._company = this.authService.getUser()._company;
				this.loaderService.newLoader(`Criando ${i} de ${gatherings.length}`);
				const res = await this.gatheringService.create(gathering).toPromise();
				if (!res.success) {
					if (res.gathering && !res.gathering.sample.serviceOrder) {
						const { sample } = res.gathering;
						const newSampleValue = { ...sample, serviceOrder: gathering.sample.serviceOrder };
						await this.gatheringService.update(res.gathering._id, { sample: newSampleValue }).toPromise();
					} else {
						alert(`Amostra com erro: ${gathering.sample.serviceOrder}.
						\nMensagem: ${res.error}\n\n
						Pressione Ok para continuar a importação.`);
					}
				}
				i++;
				this.loaderService.stopLoader();
			}

			alert("Importação finalizada");
			this.getData();
		}
	}

	exportPclGatheringReportToCsvAnoAtual() {
		this.loaderService.newLoader("Carregando relatório.\nIsso pode demorar um pouco...");
		this.csvColumns = [
			{ name: "CODIGO_COLETA", propertie: "CODIGO_COLETA" },
			{ name: "STATUS", propertie: "STATUS" },
			{ name: "NOME_PACIENTE", propertie: "NOME_PACIENTE" },
			{ name: "DATA_NASCIMENTO", propertie: "DATA_NASCIMENTO" },
			{ name: "PACIENTE_CPF", propertie: "PACIENTE_CPF" },
			{ name: "PCL_CNPJ", propertie: "PCL_CNPJ" },
			{ name: "PCL_RAZAO_SOCIAL", propertie: "PCL_RAZAO_SOCIAL" },
			{ name: "DIA_COLETA", propertie: "DIA_COLETA" },
			{ name: "MES_COLETA", propertie: "MES_COLETA" },
			{ name: "ANO_COLETA", propertie: "ANO_COLETA" },
			{ name: "HORA_COLETA", propertie: "HORA_COLETA" },
			{ name: "DIA_RECEBIMENTO", propertie: "DIA_RECEBIMENTO" },
			{ name: "MES_RECEBIMENTO", propertie: "MES_RECEBIMENTO" },
			{ name: "ANO_RECEBIMENTO", propertie: "ANO_RECEBIMENTO" },
			{ name: "HORA_RECEBIMENTO", propertie: "HORA_RECEBIMENTO" },
			{ name: "DIA_RESULTADO", propertie: "DIA_RESULTADO" },
			{ name: "MES_RESULTADO", propertie: "MES_RESULTADO" },
			{ name: "ANO_RESULTADO", propertie: "ANO_RESULTADO" },
			{ name: "HORA_RESULTADO", propertie: "HORA_RESULTADO" }
		];

		this.gatheringService.getForCsvPclAnoAtual().subscribe(async (response: any) => {
			const data = [];

			for await (const element of response) {
				const obj = {
					CODIGO_COLETA: element.CODIGO_COLETA || "",
					STATUS: element.STATUS || "",
					NOME_PACIENTE: element.NOME_PACIENTE || "",
					DATA_NASCIMENTO: element.DATA_NASCIMENTO || "",
					PACIENTE_CPF: element.PACIENTE_CPF || "",
					PCL_CNPJ: element.PCL_CNPJ || "",
					PCL_RAZAO_SOCIAL: element.PCL_RAZAO_SOCIAL || "",
					DIA_COLETA: element.DIA_COLETA || "",
					MES_COLETA: element.MES_COLETA || "",
					ANO_COLETA: element.ANO_COLETA || "",
					HORA_COLETA: element.HORA_COLETA || "",
					DIA_RECEBIMENTO: element.DIA_RECEBIMENTO || "",
					MES_RECEBIMENTO: element.MES_RECEBIMENTO || "",
					ANO_RECEBIMENTO: element.ANO_RECEBIMENTO || "",
					HORA_RECEBIMENTO: element.HORA_RECEBIMENTO || "",
					DIA_RESULTADO: element.DIA_RESULTADO || "",
					MES_RESULTADO: element.MES_RESULTADO || "",
					ANO_RESULTADO: element.ANO_RESULTADO || "",
					HORA_RESULTADO: element.HORA_RESULTADO || ""
				};
				data.push(obj);
			}
			this.csvService.jsonToCsv(this.csvColumns, data).subscribe((csv) => {
				let date = new Date();
				let fileName: string = `Relatório - 2022 - de Lista de Coletas_${date.toLocaleTimeString("pt-BR")}`;
				this.csvService.download(csv, fileName);
			});
			this.loaderService.stopLoader();
		});
	}

	exportPclGatheringReportToCsv() {
		this.loaderService.newLoader("Carregando relatório.\nIsso pode demorar um pouco...");
		this.createPclReportCsvColumns();
		const query = {
			filter: this.filter.value || "",
			status: this.status.value || "",
			pj: this.pj ? this.pj._id : "",
			birthDate: this.birthDate.value || "",
			receptionInitialDate: this.receptionInitialDate.value || "",
			receptionFinalDate: this.receptionFinalDate.value || "",
			gatheringInitialDate: this.gatheringInitialDate.value || "",
			gatheringFinalDate: this.gatheringFinalDate.value || "",
			resultInitialDate: this.resultInitialDate.value || "",
			resultFinalDate: this.resultFinalDate.value || ""
		};

		this.gatheringService.getForCsvPcl(query).subscribe(async (response: any) => {
			const data = [];

			for await (const element of response) {
				const obj = {
					createdAt: moment(element.createdAt).format("DD/MM/YYYY"),
					donor: element.donor.name || "",
					sample: element.sample.code || "",
					gathering: moment(element.sample.gatheringDate).format("DD/MM/YYYY"),
					reception: element.receivedAt ? moment(element.receivedAt).format("DD/MM/YYYY") : "#",
					result: element.analysis ? element.analysis.result : "",
					resultDate: element.analysis ? moment(element.analysis.resultDate).format("DD/MM/YYYY") : "",
					payment: element.payment.type || "",
					price: element.payment.price || "",
					balance: element.payment.balance == 0 ? "Pago" : "Pendente",
					status: element.status || "",
					cpf: element.donor.cpf || "",
					cnpj: (element._pcl && element._pcl.cnpj.toString()) || (element._company && element._company.cnpj.toString()) || ""
				};
				data.push(obj);
			}
			this.csvService.jsonToCsv(this.csvColumns, data).subscribe((csv) => {
				let date = new Date();
				let fileName: string = `Relatório de Lista de Coletas_${date.toLocaleTimeString("pt-BR")}`;
				this.csvService.download(csv, fileName);
			});
			this.loaderService.stopLoader();
		});
	}
	createPclReportCsvColumns() {
		// Cria as colunas para o csv
		this.csvColumns = [
			{ name: "CADASTRO", propertie: "createdAt" },
			{ name: "CPF", propertie: "cpf" },
			{ name: "PACIENTE", propertie: "donor" },
			{ name: "AMOSTRA", propertie: "sample" },
			{ name: "DATA DA COLETA", propertie: "gathering" },
			{ name: "DATA DE RECEPÇÃO", propertie: "reception" },
			{ name: "RESULTADO", propertie: "result" },
			{ name: "DATA DO RESULTADO", propertie: "resultDate" },
			{ name: "PAGAMENTO", propertie: "payment" },
			{ name: "VALOR", propertie: "price" },
			{ name: "SITUAÇÃO", propertie: "balance" },
			{ name: "STATUS", propertie: "status" },
			{ name: "CNPJ", propertie: "cnpj" }
		];
	}

	get filter() {
		return this.filters.get("filter");
	}
	get status() {
		return this.filters.get("status");
	}
	get birthDate() {
		return this.filters.get("birthDate");
	}
	get receptionInitialDate() {
		return this.filters.get("receptionInitialDate");
	}
	get receptionFinalDate() {
		return this.filters.get("receptionFinalDate");
	}
	get gatheringInitialDate() {
		return this.filters.get("gatheringInitialDate");
	}
	get gatheringFinalDate() {
		return this.filters.get("gatheringFinalDate");
	}
	get resultInitialDate() {
		return this.filters.get("gatheringInitialDate");
	}
	get resultFinalDate() {
		return this.filters.get("gatheringFinalDate");
	}
}
