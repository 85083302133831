import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-sample-defective-list",
	templateUrl: "./defective-list.component.html",
	styleUrls: ["./defective-list.component.scss"]
})
export class SampleDefectiveListComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
