import { Component, ViewEncapsulation } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";
import { NgxPermissionsModule } from "ngx-permissions";
import { PermissionsService } from "src/app/services/permissions/permissions.service";

@Component({
	selector: "app-sidenav",
	templateUrl: "./sidenav.component.html",
	styleUrls: ["./sidenav.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class SidenavComponent {
	userType: string = "";
	expanded = false;
	navigation;
	selectedCategory;

	constructor(private http: HttpClient, private router: Router, private authService: AuthService, private permissionsServ: PermissionsService) {
		if (this.authService.isAuthenticated()) {
			this.userType = this.authService.getUser().userType;
			this.permissionsServ.loadPermissions();
		}
		this.http.get("/assets/json/navigation.json").subscribe((response: any[]) => {
			this.navigation = response
				.map((item) => {
					if (item.permit === "*" || item.permit.includes(this.userType)) {
						item.routes = item.routes.filter((route: any) => route.permit === "*" || route.permit.includes(this.userType));
						return item;
					}
					return;
				})
				.filter((item) => item);
		});
	}

	openPage(url) {
		this.router.navigate([url]);
		this.closeNav();
	}

	selectCategory(category) {
		this.selectedCategory = category;
	}

	expansion() {
		this.expanded = !this.expanded;
	}

	activeCategory(category) {
		const url = this.router.url.split("/")[1];
		return category == url;
	}

	activeRoute(route, category) {
		const url = this.router.url.split("/");
		return url[2] == route && url[1] == category;
	}

	closeNav() {
		this.expanded = false;
		this.selectedCategory = null;
	}
}
