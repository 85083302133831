import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as XLSX from "xlsx";

import { MatDialogRef } from "@angular/material";
import { FormControl, Validators } from "@angular/forms";

@Component({
	selector: "app-result-modal",
	templateUrl: "./result-modal.component.html",
	styleUrls: ["./result-modal.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class ResultModalComponent implements OnInit {
	arrayBuffer: any;
	file: File;
	lot = new FormControl("", [Validators.required]);
	typeOfMaterial = "Swab Naso-orofaríngeo";

	constructor(public dialogRef: MatDialogRef<ResultModalComponent>) {}

	ngOnInit() {}

	incomingfile(event) {
		this.file = event.target.files[0];
	}

	readXlsxAndCloseDialog() {
		if (this.lot.valid) {
			const fileReader = new FileReader();
			fileReader.onload = (e) => {
				this.arrayBuffer = fileReader.result;
				const data = new Uint8Array(this.arrayBuffer);
				const arr = new Array();
				for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
				const bstr = arr.join("");
				const workbook = XLSX.read(bstr, { type: "binary" });
				const first_sheet_name = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[first_sheet_name];

				const importedGatherings = XLSX.utils.sheet_to_json(worksheet, { raw: true });
				const gatherings = importedGatherings.map((item: any) => {
					return {
						code: item.CODIGO,
						result: item.RESULTADO
					};
				});

				this.dialogRef.close({
					lot: this.lot.value,
					typeOfMaterial: this.typeOfMaterial,
					gatherings
				});
			};
			fileReader.readAsArrayBuffer(this.file);
		}
	}
}
