import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { FormControl, Validators } from "@angular/forms";

@Component({
	selector: "app-export-xml-modal",
	templateUrl: "./export-xml-modal.component.html",
	styleUrls: ["./export-xml-modal.component.scss"]
})
export class ExportXmlModalComponent implements OnInit {
	start = new FormControl("");
	end = new FormControl("");
	exported = new FormControl(false);
	searchByDate = new FormControl(false);

	constructor(public dialogRef: MatDialogRef<ExportXmlModalComponent>) {}

	ngOnInit() {}

	save() {
		const response: any = {
			exported: this.exported.value,
			start: "",
			end: ""
		};

		// if (this.searchByDate.value) {
		//   response.start = this.start.value;
		//   response.end = this.end.value;
		// }

		this.dialogRef.close(response);
	}
}
