import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog } from "@angular/material";
import { FormControl } from "@angular/forms";
import { CompanyService } from "src/app/services/company/company.service";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { ChangeLimitComponent } from "src/app/components/financial/change-limit/change-limit.component";
import { ChangeBalanceComponent } from "src/app/components/financial/change-balance/change-balance.component";
import { CsvService } from "src/app/services/csv/csv.service";
import * as moment from "node_modules/moment/moment";
import { GatheringService } from "src/app/services/gathering/gathering.service";
import { WalletService } from "src/app/services/wallet/wallet.service";
import { PaymentService } from "src/app/services/payment/payment.service";
import { TwoButtonZeroInputComponent } from "src/app/shared/modals/two-button-zero-input/two-button-zero-input.component";

@Component({
	selector: "app-financial-companies",
	templateUrl: "./companies.component.html",
	styleUrls: ["./companies.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class FinancialCompaniesComponent implements OnInit {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	columns = ["cnpj", "legalName", "balance", "limit", "blocked", "actions"];
	csvColumns;
	dataSource = new MatTableDataSource();
	filter: FormControl = new FormControl("");
	length = 0;

	constructor(
		private companyService: CompanyService,
		private csvService: CsvService,
		private dialog: MatDialog,
		private loaderService: LoaderService,
		private walletService: WalletService,
		private paymentService: PaymentService,
		private gatheringService: GatheringService
	) {}

	ngOnInit() {
		this.getData();
		this.createObservables();
	}

	createObservables() {
		this.filter.valueChanges
			.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				tap(() => this.getData())
			)
			.subscribe();
		this.paginator.page.subscribe(() => this.loadData());
	}

	getData() {
		this.paginator.pageIndex = 0;
		this.loadData();
	}

	loadData() {
		const query = {
			filter: this.filter.value,
			page: this.paginator.pageIndex,
			limit: this.paginator.pageSize ? this.paginator.pageSize : 10
		};

		this.loaderService.newLoader("Buscando Empresas");
		this.companyService.listCompanies(query).subscribe((response: any) => {
			this.length = response.count;
			this.dataSource.data = response.companies;
			this.loaderService.stopLoader();
		});
	}

	changeLimit(elem) {
		const dialogRef = this.dialog.open(ChangeLimitComponent, {
			data: {
				_id: elem._id,
				title: "Alterar limite",
				label: "Novo limite"
			}
		});

		dialogRef.afterClosed().subscribe((res: any) => {
			if (res) {
				this.companyService.update(res._id, { "wallet.creditLimit": res.value }).subscribe((res: any) => {
					if (res.success) {
						this.getData();
						return alert("Limite alterado com sucesso");
					}

					return alert("Erro ao alterar limite, entre em contato com o suporte");
				});
			}
		});
	}

	changeBalance(elem) {
		const dialogRef = this.dialog.open(ChangeBalanceComponent, { data: { _id: elem._id } });

		dialogRef.afterClosed().subscribe((res: any) => {
			if (res) {
				this.walletService.addBalance({ ...res, type: "company" }).subscribe((response: any) => {
					if (response.success) {
						this.getData();
						const { _id } = res;
						delete res._id;
						this.paymentService.create({ _company: _id, ...res, gatherings: response.gatherings }).subscribe();
						return alert("Saldo adicionado com sucesso");
					}

					return alert("Erro ao adicionar saldo, entre em contato com o suporte");
				});
			}
		});
	}

	changeExamPrice(elem) {
		const dialogRef = this.dialog.open(ChangeLimitComponent, {
			data: {
				_id: elem._id,
				title: "Alterar preço do exame",
				label: "Preço do exame"
			}
		});

		dialogRef.afterClosed().subscribe((res: any) => {
			if (res) {
				this.companyService.update(res._id, { "commercialCondition.examPrice": res.value }).subscribe((res: any) => {
					if (res.success) {
						this.getData();
						return alert("Preço alterado com sucesso");
					}

					return alert("Erro ao alterar preço, entre em contato com o suporte");
				});
			}
		});
	}

	exportCompanyGatheringReportToCsv(company) {
		this.createCompanyReportCsvColumns();

		this.gatheringService
			.getForCsvCompany({
				_company: company._id
			})
			.subscribe((response: any) => {
				const data = [];

				response.forEach((element) => {
					const obj = {
						createdAt: moment(element.createdAt).format("DD/MM/YYYY"),
						donor: element.donor.name,
						sample: element.sample.code,
						gathering: moment(element.sample.gatheringDate).format("DD/MM/YYYY"),
						reception: element.receivedAt ? moment(element.receivedAt).format("DD/MM/YYYY") : "#",
						result: element.analysis ? element.analysis.result : "",
						resultDate: element.analysis ? moment(element.analysis.resultDate).format("DD/MM/YYYY") : "",
						payment: element.payment.type,
						price: element.payment.price,
						balance: element.payment.balance == 0 ? "Pago" : "Pendente",
						status: element.status,
						cpf: element.donor.cpf
					};
					data.push(obj);
				});

				this.csvService.jsonToCsv(this.csvColumns, data).subscribe((csv) => {
					let date = new Date();
					let fileName: string = `${company.legalName}_${date.toLocaleDateString("pt-BR")}_${date.toLocaleTimeString("pt-BR")}`;
					this.csvService.download(csv, fileName);
				});
			});
	}

	createCompanyReportCsvColumns() {
		// Cria as colunas para o csv
		this.csvColumns = [
			{ name: "CADASTRO", propertie: "createdAt" },
			{ name: "CPF", propertie: "cpf" },
			{ name: "PACIENTE", propertie: "donor" },
			{ name: "AMOSTRA", propertie: "sample" },
			{ name: "DATA DA COLETA", propertie: "gathering" },
			{ name: "DATA DE RECEPÇÃO", propertie: "reception" },
			{ name: "RESULTADO", propertie: "result" },
			{ name: "DATA DO RESULTADO", propertie: "resultDate" },
			{ name: "PAGAMENTO", propertie: "payment" },
			{ name: "VALOR", propertie: "price" },
			{ name: "SITUAÇÃO", propertie: "balance" },
			{ name: "STATUS", propertie: "status" }
		];
	}

	exportToCsv() {
		this.loaderService.newLoader("Fazendo download do CSV");
		this.createCsvColumns();

		this.companyService.getCompanyPCL().subscribe((response: any) => {
			const data = [];

			response.data.forEach((element) => {
				const obj = {
					cnpj: element.cnpj,
					legalName: element.legalName,
					examPrice: element.commercialCondition.examPrice,
					balance: element.wallet.balance,
					creditLimit: element.wallet.creditLimit,
					type: element.type
				};
				data.push(obj);
			});

			this.csvService.jsonToCsv(this.csvColumns, data).subscribe((csv) => {
				let date = new Date();
				let fileName: string = `Detalhes Financeiros - Empresas e PCLS_${date.toLocaleDateString("pt-BR")}_${date.toLocaleTimeString("pt-BR")}`;
				this.csvService.download(csv, fileName);
				this.loaderService.stopLoader();
			});
		});
	}

	createCsvColumns() {
		// Cria as colunas para o csv
		this.csvColumns = [
			{ name: "CNPJ", propertie: "cnpj" },
			{ name: "RAZÃO SOCIAL", propertie: "legalName" },
			{ name: "PREÇO DO EXAME", propertie: "examPrice" },
			{ name: "SALDO", propertie: "balance" },
			{ name: "LIMITE DE CRÉDITO", propertie: "creditLimit" },
			{ name: "TIPO", propertie: "type" }
		];
	}

	releaseGatherings(elem) {
		this.loaderService.newLoader("Liberando coletas");
		this.gatheringService.waitingPaymentCompanyRelease(elem._id).subscribe(() => {
			this.loaderService.stopLoader();
		});
	}

	toggleBlock(elem) {
		const text = elem.blocked ? "Deseja desbloquear a empresa?" : "Deseja bloquear a empresa?";
		const dialogRef = this.dialog.open(TwoButtonZeroInputComponent, {
			data: {
				data: {
					title: "Bloquear",
					text: text,
					buttonLeft: "Não",
					buttonRight: elem.blocked ? "Desbloquear" : "Bloquear"
				}
			}
		});

		dialogRef.afterClosed().subscribe((res: any) => {
			if (res) {
				const blocked = !elem.blocked;
				this.loaderService.newLoader("Bloqueando empresa");
				this.companyService.update(elem._id, { blocked: blocked }).subscribe(() => {
					this.loaderService.stopLoader();
					this.loadData();
				});
			}
		});
	}
}
