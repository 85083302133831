import { Component, ViewEncapsulation, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-change-balance",
	templateUrl: "./change-balance.component.html",
	styleUrls: ["./change-balance.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class ChangeBalanceComponent {
	form: FormGroup;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ChangeBalanceComponent>, private fb: FormBuilder) {
		this.form = this.fb.group({
			_id: [this.data._id, Validators.required],
			value: [0, Validators.required],
			reason: ["", Validators.required],
			url: [""]
		});
	}

	save(data) {
		this.dialogRef.close(data);
	}

	cancel() {
		this.dialogRef.close(null);
	}
}
