import { Component, ViewEncapsulation, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
	selector: "app-one-button-zero-input",
	templateUrl: "./one-button-zero-input.component.html",
	styleUrls: ["./one-button-zero-input.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class OneButtonZeroInputComponent  {
	content = {
		title: "",
		text: "",
		button: "",
		msg: "",
		cssClass: ""
	};

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<OneButtonZeroInputComponent>) {
		// console.log("data:", data);
		
		this.content.title = data.title ? data.title : "";
		this.content.text = data.text ? data.text : "";
		this.content.button = data.button ? data.button : "OK";
		this.content.msg = data.msg ? data.msg : "";
	}

	close() {
		this.dialogRef.close(null);
	}
}
