import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { SampleRegisterComponent } from "src/app/components/sample/register/register.component";
import { GatheringService } from "src/app/services/gathering/gathering.service";
import * as moment from "node_modules/moment/moment";
import { WalletService } from "src/app/services/wallet/wallet.service";
import { Router } from "@angular/router";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { TwoButtonOneInputComponent } from "src/app/shared/modals/two-button-one-input/two-button-one-input.component";

@Component({
	selector: "app-sample-reception",
	templateUrl: "./reception.component.html",
	styleUrls: ["./reception.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class SampleReceptionComponent implements OnInit {
	@ViewChild("tubecode", { static: true }) tubecodeEl: ElementRef;
	@ViewChild("formcode", { static: true }) formcodeEl: ElementRef;
	formCode = new FormControl("");
	tubeCode = new FormControl("");
	sample: any;

	constructor(
		private dialog: MatDialog,
		private gatheringProv: GatheringService,
		private loaderService: LoaderService,
		private walletService: WalletService,
		private router: Router
	) {}

	ngOnInit() {
		this.tubecodeEl.nativeElement.focus();
		this.createObservables();
	}

	createObservables() {
		this.formCode.valueChanges.subscribe((value) => {
			if (value.length >= 9 && this.tubeCode.value.length >= 9) this.compareValues();
		});
		this.tubeCode.valueChanges.subscribe((value) => {
			if (value.length >= 9) {
				this.formcodeEl.nativeElement.focus();
			}
		});
	}

	// COMPARA OS CÓDIGOS INSERIDOS
	async compareValues() {
		// C00001AA - EXEMPLO DE CÓDIGO
		if (this.formCode.value.length < 8 || this.tubeCode.value.length < 8) {
			alert("Codigo de amostra muito curto");
			return;
		}

		if (this.formCode.value.trim() == this.tubeCode.value.trim()) {
			this.loaderService.newLoader();
			const sampleExists: any = await this.checkIfExists();
			this.loaderService.stopLoader();

			if (sampleExists) {
				if (!sampleExists.receivedAt) {
					this.setSample(sampleExists);
				} else {
					alert("Amostra ja recebida");
					this.clearPage();
				}
			} else {
				this.registerSample(this.tubeCode.value);
			}
		} else {
			alert("Código de amostra e código de formulário estão diferentes.");
		}
	}

	// VERIFICA SE A AMOSTRA EXISTE
	async checkIfExists() {
		return await this.gatheringProv.getByCode(this.tubeCode.value).toPromise();
	}

	setSample(sample) {
		this.sample = sample;
		this.sample.sample.gatheringDate = moment(this.sample.sample.gatheringDate).format("DD/MM/YYYY");
		this.sample.createdAt = moment(this.sample.createdAt).format("DD/MM/YYYY");
	}

	// MODAL PARA REGISTRAR A AMOSTRA
	registerSample(code, insideCode = true) {
		const register = this.dialog.open(SampleRegisterComponent, { data: { code, insideCode } });

		register.afterClosed().subscribe((res) => {
			if (res) this.setSample(res);
		});
	}

	outsideGathering() {
		this.loaderService.newLoader();
		this.gatheringProv.generateCodeForOutsideGatherings().subscribe(({ code }: any) => {
			this.loaderService.stopLoader();
			this.registerSample(code, false);
		});
	}

	clearPage() {
		this.sample = null;
		this.formCode.setValue("");
		this.tubeCode.setValue("");
	}

	// VERIFICA SE O PCL POSSUI LIMITE DISPONÍVEL E FAZ A COBRANÇA.
	checkLimitAndPay(_id: string) {
		this.loaderService.newLoader();
		this.walletService.chargeGathering(_id).subscribe((response: any) => {
			this.loaderService.stopLoader();
			if (response.success) {
				if (confirm("Amostra enviada para analíse, deseja continuar recebendo amostras?")) {
					return this.clearPage();
				}
				return this.router.navigate(["home"]);
			}

			if (confirm("Amostra enviada para lista de aguardando pagamento, continuar recebendo amostras?")) {
				return this.clearPage();
			}
			return this.router.navigate(["home"]);
		});
	}

	refuseSample(_id: string) {
		const confirmRefuse = this.dialog.open(TwoButtonOneInputComponent, {
			data: {
				title: "Tem certeza que deseja rejeitar?",
				label: "Justificativa"
			}
		});

		confirmRefuse.afterClosed().subscribe((res) => {
			if (res) {
				this.loaderService.newLoader();
				this.gatheringProv.refuseSample(_id, res).subscribe((response: any) => {
					this.loaderService.stopLoader();
					if (response.success) {
						return this.clearPage();
					}
				});
			}
		});
	}
}
