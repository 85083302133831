import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
	MatToolbarModule,
	MatCardModule,
	MatButtonModule,
	MatInputModule,
	MatFormFieldModule,
	MatTabsModule,
	MatExpansionModule,
	MatListModule,
	MatIconModule,
	MatChipsModule,
	MatDialogModule,
	MatSelectModule,
	MatStepperModule,
	MatSnackBarModule,
	MatPaginatorModule,
	MatSortModule,
	MatTableModule,
	MatMenuModule,
	MatCheckboxModule,
	MatRadioModule,
	MatTooltipModule,
	MatAutocompleteModule,
	MatNativeDateModule,
	MAT_DATE_LOCALE,
	MatProgressSpinnerModule,
	MatDatepickerModule
} from "@angular/material";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpParams } from "@angular/common/http";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { CurrencyPipe } from "@angular/common";
import { CurrencyMaskModule } from "ngx-currency-mask";
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ngx-currency-mask/src/currency-mask.config";
import { ClickOutsideModule } from "ng-click-outside";
import { NgxPermissionsModule } from "ngx-permissions";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AuthInterceptor } from "./services/auth/auth.interceptor";
import { PCLRegisterComponent } from "./pages/pcl/register/register.component";
import { PCLListComponent } from "./pages/pcl/list/list.component";
import { LoginComponent } from "./pages/login/login.component";
import { GatheringRegisterComponent } from "./pages/gathering/register/register.component";
import { GatheringListComponent } from "./pages/gathering/list/list.component";
import { HomeComponent } from "./pages/home/home.component";
import { CompanyRegisterComponent } from "./pages/company/register/register.component";
import { CompanyListComponent } from "./pages/company/list/list.component";
import { SampleAnalysisComponent } from "./pages/sample/analysis/analysis.component";
import { SampleReceptionComponent } from "./pages/sample/reception/reception.component";
import { SampleDefectiveListComponent } from "./pages/sample/defective-list/defective-list.component";
import { SampleRegisterComponent } from "./components/sample/register/register.component";
import { SidenavComponent } from "./shared/sidenav/sidenav.component";
import { MainHeaderComponent } from "./shared/headers/main-header/main-header.component";
import { FinancialPaymentsComponent } from "./pages/financial/payments/payments.component";
import { FinancialPCLsComponent } from "./pages/financial/pcls/pcls.component";
import { FinancialCompaniesComponent } from "./pages/financial/companies/companies.component";
import { UserListComponent } from "./pages/user/list/list.component";
import { UserRegisterComponent } from "./pages/user/register/register.component";
import { ResultModalComponent } from "./components/analysis/result-modal/result-modal.component";
import { ReportComponent } from "./pages/report/report.component";
import { ConferenceComponent } from "./pages/sample/conference/conference.component";
import { PermissionListComponent } from "./pages/user/permission/list/list.component";
import { PermissionRegisterComponent } from "./pages/user/permission/register/register.component";
import { LotComponent } from "./pages/sample/lot/lot.component";
import { ReportResultComponent } from "./pages/result/report-result/report-result.component";
import { SampleEditComponent } from "./pages/sample/edit/edit.component";
import { LoaderComponent } from "./shared/loader/loader.component";
import { ChangeBalanceComponent } from "./components/financial/change-balance/change-balance.component";
import { ChangeLimitComponent } from "./components/financial/change-limit/change-limit.component";
import { CsvService } from "./services/csv/csv.service";
import { PatientFormComponent } from "./pages/patient-form/patient-form.component";
import { OneButtonZeroInputComponent } from "./shared/modals/one-button-zero-input/one-button-zero-input.component";
import { TwoButtonOneInputComponent } from "./shared/modals/two-button-one-input/two-button-one-input.component";
import { TwoButtonZeroInputComponent } from "./shared/modals/two-button-zero-input/two-button-zero-input.component";
import { ExportXmlComponent } from "./pages/export-xml/export-xml.component";
import { ExportXmlModalComponent } from "./components/export/export-xml-modal/export-xml-modal.component";
import { PassportModalComponent } from "./components/sample/passport-modal/passport-modal.component";
import { ReportListComponent } from "./pages/result/report-list/report-list.component";
import { ResetPasswordComponent } from "./pages/resetPassword/resetPassword.component";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
	align: "right",
	allowNegative: false,
	allowZero: true,
	decimal: ".",
	precision: 2,
	prefix: "R$ ",
	suffix: "",
	thousands: ","
};
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json?cb=" + new Date().getTime());
}

@NgModule({
	declarations: [
		AppComponent,
		PCLRegisterComponent,
		PCLListComponent,
		LoginComponent,
		ResetPasswordComponent,
		GatheringRegisterComponent,
		GatheringListComponent,
		HomeComponent,
		CompanyRegisterComponent,
		CompanyListComponent,
		SampleAnalysisComponent,
		SampleReceptionComponent,
		SampleDefectiveListComponent,
		SampleRegisterComponent,
		SidenavComponent,
		MainHeaderComponent,
		FinancialPaymentsComponent,
		FinancialPCLsComponent,
		FinancialCompaniesComponent,
		UserListComponent,
		UserRegisterComponent,
		ResultModalComponent,
		ReportComponent,
		ConferenceComponent,
		PermissionListComponent,
		PermissionRegisterComponent,
		LotComponent,
		ReportResultComponent,
		SampleEditComponent,
		LoaderComponent,
		ChangeBalanceComponent,
		ChangeLimitComponent,
		PatientFormComponent,
		OneButtonZeroInputComponent,
		TwoButtonOneInputComponent,
		TwoButtonZeroInputComponent,
		ExportXmlComponent,
		ExportXmlModalComponent,
		PassportModalComponent,
		ReportListComponent
	],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		ClickOutsideModule,
		CurrencyMaskModule,
		FormsModule,
		HttpClientModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatSnackBarModule,
		MatSortModule,
		MatStepperModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatRadioModule,
		NgxMaskModule.forRoot(options),
		NgxPermissionsModule.forRoot(),
		NgxQRCodeModule,
		ReactiveFormsModule,
		MatAutocompleteModule,
		ReactiveFormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		})
	],
	entryComponents: [
		SampleRegisterComponent,
		ResultModalComponent,
		ChangeBalanceComponent,
		ChangeLimitComponent,
		OneButtonZeroInputComponent,
		TwoButtonOneInputComponent,
		TwoButtonZeroInputComponent,
		ExportXmlModalComponent,
		PassportModalComponent
	],
	providers: [
		HttpClient,
		HttpParams,
		CurrencyPipe,
		CsvService,
		{ provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
		{ provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
