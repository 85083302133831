import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GatheringService } from "src/app/services/gathering/gathering.service";
import { LoaderService } from "src/app/shared/loader/loader.service";
import * as moment from "node_modules/moment/moment";
import { BarcodeService } from "src/app/services/bacorde/barcode.service";

@Component({
	selector: "app-patient-form",
	templateUrl: "./patient-form.component.html",
	styleUrls: ["./patient-form.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class PatientFormComponent implements OnInit {
	barcode: string;
	code: string;
	id: string;
	gathering: any;
	loading: boolean = true;
	@Input() sample: string;
	squares = {
		name: [
			0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46,
			47, 48, 49, 50, 51
		],
		cpf: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
		phone: [2, 3, 4, 5, 6, 7, 8, 9, 10],
		email: [
			0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46,
			47, 48, 49, 50, 51
		],
		cnpj: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
		city: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
	};

	constructor(private barcodeService: BarcodeService, private gatheringService: GatheringService, private loaderService: LoaderService, private route: ActivatedRoute) {}

	ngOnInit() {
		this.id = this.route.snapshot.paramMap.get("id") || this.sample;
		this.getGathering();
	}

	getBarcode() {
		this.barcodeService.newCode(this.code).subscribe((res: any) => (this.barcode = res.barcode));
	}

	getGathering() {
		this.loading = true;
		this.loaderService.newLoader("Buscando Informações");
		this.gatheringService.getById(this.id).subscribe((res) => {
			this.gathering = res;
			if (this.gathering) {
				this.code = this.gathering.sample.code;
				this.getBarcode();
				this.gathering.sample.gatheringDate = moment(this.gathering.sample.gatheringDate).format("DD/MM/YYYY");
				if (this.gathering.analysis) {
					this.gathering.analysis.resultDate = this.gathering.analysis ? moment(this.gathering.analysis.resultDate).format("DD/MM/YYYY") : null;
				}
				this.gathering.donor.birthDate = this.gathering.donor.birthDate ? moment(this.gathering.donor.birthDate).format("DD/MM/YYYY") : null;
				if (this.gathering._pcl) this.gathering.cnpj = this.gathering._pcl;
				if (this.gathering._company) this.gathering.cnpj = this.gathering._company;
			}
			this.loaderService.stopLoader();
			this.loading = false;
		});
	}

	buggedSquare(string, square) {
		if (string) return !string.charAt(square) || string.charAt(square) == " ";
		return true;
	}

	print() {
		window.print();
	}
}
