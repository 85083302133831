import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class LoaderService {
	private loader = new BehaviorSubject<Loader>({ text: "teste" });
	private hidden = new BehaviorSubject<any>({});
	load = this.loader.asObservable();
	stop = this.hidden.asObservable();

	constructor() {}

	newLoader(text?, loader?) {
		this.loader.next({ text, loader });
	}

	stopLoader() {
		this.hidden.next({});
	}
}

interface Loader {
	text?: string;
	loader?: {
		diameter?: number;
		mode?: string;
		strokeWidth?: number;
		time?: number;
		value?: number;
	};
}
