import { Component, OnInit, ViewChild, NgModule, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtilsService } from "src/app/services/utils.service";
import { MatStepper } from "@angular/material";
import { NgxMaskModule } from "ngx-mask";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/user/user.service";
import { PclService } from "src/app/services/pcl/pcl.service";
import { LoaderService } from "src/app/shared/loader/loader.service";

@NgModule({ imports: [NgxMaskModule] })
@Component({
	selector: "app-pcl-register",
	templateUrl: "./register.component.html",
	styleUrls: ["./register.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class PCLRegisterComponent implements OnInit {
	@ViewChild("stepper", null) stepper: MatStepper;
	filteredCities: any[];
	states: any[];
	cities: any[];
	form: FormGroup;
	_pclId: string;

	constructor(
		private fb: FormBuilder,
		private http: HttpClient,
		private loaderService: LoaderService,
		private utils: UtilsService,
		private pclService: PclService,
		private router: Router,
		private userService: UserService
	) {
		this.http.get("../../../assets/json/states.json").subscribe((response: any[]) => (this.states = response));
		this.http.get("../../../assets/json/cities.json").subscribe((response: any[]) => (this.cities = response));

		this.form = this.fb.group({
			pcl: this.fb.group({
				cnpj: ["", [Validators.required, this.utils.validateCnpjOrNull]],
				legalName: ["", Validators.required],
				stateRegistration: [""],
				fantasyName: ["", Validators.required],
				email: ["", [Validators.required, Validators.email]],
				contact: this.fb.group({
					phone: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(11)]]
				}),
				address: this.fb.group({
					postalCode: ["", Validators.required],
					address: ["", Validators.required],
					complement: [""],
					number: ["", Validators.required],
					neighbourhood: ["", Validators.required],
					state: {
						code: ["", Validators.required],
						name: ["", Validators.required]
					},
					city: ["", Validators.required],
					ibgeCode: [""]
				})
			}),
			commercialCondition: this.fb.group({
				examPrice: ["", Validators.required],
				creditLimit: ["", Validators.required],
				showOnSite: [false, Validators.required],
				householdGathering: [false, Validators.required],
				householdPrice: [{ value: "", disabled: true }, Validators.required],
				balconyPrice: ["", Validators.required],
				representative: [""]
			}),
			financialContact: this.fb.group({
				name: ["", Validators.required],
				cpf: ["", [Validators.required, this.utils.validateCpf]],
				email: ["", [Validators.required, Validators.email]],
				phone: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(11)]]
			}),
			administrativeContact: this.fb.group({
				name: ["", Validators.required],
				cpf: ["", [Validators.required, this.utils.validateCpf]],
				email: ["", [Validators.required, Validators.email]],
				phone: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(11)]]
			}),
			tecnicalContact: this.fb.group({
				name: ["", Validators.required],
				cpf: ["", [Validators.required, this.utils.validateCpf]],
				email: ["", [Validators.required, Validators.email]],
				phone: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(11)]]
			}),
			user: this.fb.group({
				email: ["", [Validators.required, Validators.email]],
				password: ["", Validators.required]
			})
		});
	}

	ngOnInit() {
		this.createObservables();
	}

	createObservables() {
		this.state.valueChanges.subscribe((element) => {
			if (element) this.filteredCities = this.cities.filter((city) => city.UF == element.code);
		});

		this.city.valueChanges.subscribe((element) => {
			if (element) this.ibgeCode.patchValue(this.cities.find((city) => city.name == element).code);
		});

		this.householdGathering.valueChanges.subscribe((value) => {
			if (value) this.householdPrice.enable();
			else this.householdPrice.disable();
		});
	}

	async save(form: any) {
		if (this._pclId) {
			const newUser = await this.createPclUser(this._pclId);
			if (newUser) {
				return this.router.navigate(["pcl/listar"]);
			}
		}

		let _form = Object.assign(form.pcl, form);

		_form = Object.assign(_form.user, _form);
		delete _form.pcl;
		delete _form.user;
		_form.wallet = {};
		_form.wallet.creditLimit = _form.commercialCondition.creditLimit;

		return this.pclService.create(_form).subscribe(
			async (response: any) => {
				const newUser = await this.createPclUser(response._id);
				if (newUser) {
					this.router.navigate(["pcl/listar"]);
				}

				this._pclId = response._id;
			},
			({ error }) => {
				console.log(error);
				alert(error.message[0]);
			}
		);
	}

	async createPclUser(_pcl: string): Promise<any> {
		try {
			const user: any = {
				email: this.email.value,
				password: this.password.value,
				userType: "pcl",
				role: "pclAdmin",
				name: this.aname.value,
				"contact.phone": this.aphone.value,
				_pcl: _pcl
			};

			this.loaderService.newLoader();
			await this.userService.create(user).toPromise();
			this.loaderService.stopLoader();
			return true;
		} catch (error) {
			this.loaderService.stopLoader();
			alert(error.message[0]);
			return false;
		}
	}

	get pcl() {
		return this.form.get("pcl");
	}
	get cnpj() {
		return this.pcl.get("cnpj");
	}
	get stateRegistration() {
		return this.pcl.get("stateRegistration");
	}
	get legalName() {
		return this.pcl.get("legalName");
	}
	get fantasyName() {
		return this.pcl.get("fantasyName");
	}
	get _email() {
		return this.pcl.get("email");
	}
	get contact() {
		return this.pcl.get("contact");
	}
	get phone() {
		return this.contact.get("phone");
	}
	get _address() {
		return this.pcl.get("address");
	}
	get postalCode() {
		return this._address.get("postalCode");
	}
	get address() {
		return this._address.get("address");
	}
	get number() {
		return this._address.get("number");
	}
	get complement() {
		return this._address.get("complement");
	}
	get neighbourhood() {
		return this._address.get("neighbourhood");
	}
	get city() {
		return this._address.get("city");
	}
	get state() {
		return this._address.get("state");
	}
	get ibgeCode() {
		return this._address.get("ibgeCode");
	}

	get commercialCondition() {
		return this.form.get("commercialCondition");
	}
	get examPrice() {
		return this.commercialCondition.get("examPrice");
	}
	get showOnSite() {
		return this.commercialCondition.get("showOnSite");
	}
	get householdGathering() {
		return this.commercialCondition.get("householdGathering");
	}
	get householdPrice() {
		return this.commercialCondition.get("householdPrice");
	}
	get balconyPrice() {
		return this.commercialCondition.get("balconyPrice");
	}
	get representative() {
		return this.commercialCondition.get("representative");
	}

	get financialContact() {
		return this.form.get("financialContact");
	}
	get fname() {
		return this.financialContact.get("name");
	}
	get fcpf() {
		return this.financialContact.get("cpf");
	}
	get femail() {
		return this.financialContact.get("email");
	}
	get fphone() {
		return this.financialContact.get("phone");
	}

	get administrativeContact() {
		return this.form.get("administrativeContact");
	}
	get aname() {
		return this.administrativeContact.get("name");
	}
	get acpf() {
		return this.administrativeContact.get("cpf");
	}
	get aemail() {
		return this.administrativeContact.get("email");
	}
	get aphone() {
		return this.administrativeContact.get("phone");
	}

	get tecnicalContact() {
		return this.form.get("tecnicalContact");
	}
	get tname() {
		return this.tecnicalContact.get("name");
	}
	get tcpf() {
		return this.tecnicalContact.get("cpf");
	}
	get temail() {
		return this.tecnicalContact.get("email");
	}
	get tphone() {
		return this.tecnicalContact.get("phone");
	}

	get user() {
		return this.form.get("user");
	}
	get email() {
		return this.user.get("email");
	}
	get password() {
		return this.user.get("password");
	}
}
