import { Component, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/user/user.service";

@Component({
	selector: "app-resetPassword",
	templateUrl: "./resetPassword.component.html",
	styleUrls: ["./resetPassword.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent {
	private newPassword: FormGroup;
	private isShowPassword = false;
	private jwtToken: any;

	constructor(private fb: FormBuilder, private userService: UserService, private route: ActivatedRoute) {
		this.newPassword = this.fb.group({
			new_password: ["", [Validators.required]],
			repeat_new_password: ["", Validators.required]
		});
	}

	ngOnInit() {
		this.jwtToken = this.route.snapshot.paramMap.get("token") || "";
	}

	get email() {
		return this.newPassword.get("new_password");
	}
	get new_password() {
		return this.newPassword.get("new_password");
	}
	get repeat_new_password() {
		return this.newPassword.get("repeat_new_password");
	}

	forgotPassword() {
		if (this.newPassword.status == "VALID") {
			if (this.new_password.value == this.repeat_new_password.value) {
				var forgot_password = this.userService.resetPassword({ jwtToken: this.jwtToken, newPassword: this.new_password.value });
			} else {
				alert("Os dados não estão batendo");
			}
		} else {
			alert("Por favor, preencha todos os campos");
		}
		return forgot_password;
	}

	showPassword() {
		this.isShowPassword = !this.isShowPassword;
	}
}
