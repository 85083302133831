import { Component, ViewEncapsulation, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
	selector: "app-change-limit",
	templateUrl: "./change-limit.component.html",
	styleUrls: ["./change-limit.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class ChangeLimitComponent {
	form: FormGroup;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ChangeLimitComponent>, private fb: FormBuilder) {
		this.form = this.fb.group({
			_id: [this.data._id, Validators.required],
			value: [0, Validators.required]
		});
	}

	save(data) {
		this.dialogRef.close(data);
	}

	cancel() {
		this.dialogRef.close(null);
	}
}
