import { Component, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { GatheringService } from "src/app/services/gathering/gathering.service";

@Component({
	selector: "app-report-result",
	templateUrl: "./report-result.component.html",
	styleUrls: ["./report-result.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class ReportResultComponent {
	result: FormGroup;
	sample: string;
	samples: any[];

	constructor(private fb: FormBuilder, private gatheringService: GatheringService, private route: ActivatedRoute) {
		this.result = this.fb.group({
			cpf: ["", [Validators.required]],
			code: ["", [Validators.required]]
		});
	}

	ngOnInit() {
		const params = this.route.snapshot.params;
		if (params.cpf) {
			this.cpf.patchValue(params.cpf);
		}
		if (params.code) {
			this.code.patchValue(params.code);
		}
		if (this.result.valid) {
			this.loadResult();
		}
	}

	loadResult() {
		this.gatheringService.getReportResult({ cpf: this.cpf.value, code: this.code.value }).subscribe(
			(response: any) => {
				if (response.success) {
					if (response.gatherings.length > 1) {
						this.samples = response.gatherings;
						return;
					}
					this.sample = response.gatherings[0]._id;
					return;
				}
				if (response.error) {
					alert(response.error);
				} else {
					alert("Amostra não encontrada ou análise não finalizada.");
				}
			},
			({ error }) => {
				console.log(error);
				alert("Erro ao encontrar o resultado.");
			}
		);
	}

	setSample(event) {
		this.samples = null;
		this.sample = event;
	}

	get cpf() {
		return this.result.get("cpf");
	}
	get code() {
		return this.result.get("code");
	}
}
