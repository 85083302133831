import { Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "src/app/services/user/user.service";
import { Router } from "@angular/router";

@Component({
	selector: "app-permission-register",
	templateUrl: "./register.component.html",
	styleUrls: ["./register.component.scss"]
})
export class PermissionRegisterComponent {
	form: FormGroup;
	types = ["permission", "role"];

	constructor(private fb: FormBuilder, private userService: UserService, private router: Router) {
		this.form = this.fb.group({
			name: ["", Validators.required],
			type: ["", Validators.required]
		});
	}

	save(data) {
		// this.userService.createPermission(data).subscribe((res: any) => {
		// 	console.log(res);
		// });
	}

	get email() {
		return this.form.get("email");
	}
	get name() {
		return this.form.get("name");
	}
}
