import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { API } from "../../app.api";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class PaymentService {
	constructor(public http: HttpClient) {}

	listPayments(filter: any) {
		return this.http.get(`${API}/payments`, {
			params: new HttpParams()
				.set("_company", filter._company || "")
				.set("_pcl", filter._pcl || "")
				.set("limit", filter.limit)
				.set("page", filter.page)
		});
	}

	create(body: any): Observable<any> {
		return this.http.post(`${API}/payments`, body);
	}
}
