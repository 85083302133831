import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const loggedIn = this.authService.isAuthenticated();
		if (loggedIn) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${this.authService.getToken()}`
				}
			});
		}

		return next.handle(request);
	}
}
