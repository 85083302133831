import { Injectable } from "@angular/core";
import { API } from "src/app/app.api";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root"
})
export class WalletService {
	constructor(private http: HttpClient) {}

	chargeGathering(_id: string) {
		return this.http.post(`${API}/wallet/charge`, {
			_id
		});
	}

	unchargeGathering(_id: string) {
		return this.http.post(`${API}/wallet/uncharge`, {
			_id
		});
	}

	addBalance(params) {
		return this.http.post(`${API}/wallet/add-balance`, params);
	}
}
