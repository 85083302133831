let apiAddress: any;

/**
 * Production
 */
apiAddress = {
	api: "https://semcovid-api-7nareqjlia-uc.a.run.app/"
};

/**
 * Development
 */
if (window.location.hostname == "localhost") {
	apiAddress = {
		api: "http://localhost:3000"
	};
}

/**
 * Export
 */
export const API = apiAddress.api;
