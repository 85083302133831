import { Component, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/services/auth/auth.service";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { MatDialog } from "@angular/material";
import { OneButtonZeroInputComponent } from "src/app/shared/modals/one-button-zero-input/one-button-zero-input.component";

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class HomeComponent {
	usertype: string = "";
	hovered;
	pages = [];

	constructor(private http: HttpClient, private loaderService: LoaderService, private router: Router, private authService: AuthService, private dialog: MatDialog) {
		this.loaderService.newLoader();
		this.http.get("/assets/json/navigation.json").subscribe((response: any[]) => {
			this.usertype = this.authService.getUser().userType;
			this.pages = response
				.map((item) => {
					if (item.permit === "*" || item.permit.includes(this.usertype)) {
						item.routes = item.routes.filter((route: any) => route.permit === "*" || route.permit.includes(this.usertype));
						return item;
					}
					return;
				})
				.filter((item) => item);
			this.loaderService.stopLoader();
		});
	}

	ngOnInit() {
		this.modal();
	}

	openPage(url) {
		this.router.navigate([url]);
	}

	hovering(category) {
		this.hovered = category;
	}

	resetHover() {
		this.hovered = null;
	}

	async modal() {
		const dialogRef = this.dialog.open(OneButtonZeroInputComponent, {
			data: {
				title: "COMUNICADO",
				text: "",
				button: "Fechar",
				msg:`<img src="/assets/img/pop-2.jpg" class="img">`
			},
		});
	}
}
