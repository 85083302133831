import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { API } from "../../app.api";

@Injectable({
	providedIn: "root"
})
export class ExportXmlService {
	constructor(public http: HttpClient) {}

	/** Route: ${API}/export-xml/exports*/
	getExportLog(query) {
		return this.http.get(`${API}/export-xml/exports`, {
			params: new HttpParams().set("page", query.page).set("limit", query.limit)
		});
	}

	/** Route: ${API}/export-xml/download*/
	getExportXmlData(filter) {
		return this.http.get(`${API}/export-xml/download`, {
			params: new HttpParams().set("exported", filter.exported).set("start", filter.start).set("end", filter.end)
		});
	}

	/** Route: ${API}/export-xml/download/${_id}*/
	getExportXmlDataById(_id: string) {
		return this.http.get(`${API}/export-xml/download/${_id}`);
	}
}
