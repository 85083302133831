import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { GatheringService } from "src/app/services/gathering/gathering.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup, FormControl } from "@angular/forms";
import { UtilsService } from "src/app/services/utils.service";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { AuthService } from "src/app/services/auth/auth.service";
import * as moment from "moment";
import { CompanyService } from "src/app/services/company/company.service";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { WalletService } from "src/app/services/wallet/wallet.service";

@Component({
	selector: "app-sample-edit",
	templateUrl: "./edit.component.html",
	styleUrls: ["./edit.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class SampleEditComponent implements OnInit {
	authUserType = "";
	editMode: boolean = false;
	pjType;
	form: FormGroup;
	gatheringId: string;
	filteredPJs: Observable<any[]>;
	pjSearchbar: FormControl = new FormControl();
	pjs = [];
	pjSelected = "";
	gathering: any;

	constructor(
		private companyService: CompanyService,
		private fb: FormBuilder,
		private gatheringService: GatheringService,
		private loaderService: LoaderService,
		private route: ActivatedRoute,
		private utils: UtilsService,
		private authService: AuthService,
		private walletService: WalletService,
		private router: Router
	) {
		this.gatheringId = this.route.snapshot.paramMap.get("id");
		this.getData();

		this.form = this.fb.group({
			donor: this.fb.group({
				cpf: [{ value: "", disabled: true }, [this.utils.validateCpf]],
				passport: [{ value: "", disabled: true }, [Validators.minLength(8)]],
				name: [{ value: "", disabled: true }, Validators.required],
				birthDate: [{ value: "", disabled: true }],
				gender: [{ value: "", disabled: true }],
				email: [{ value: "", disabled: true }, [Validators.email]],
				phone: [{ value: "", disabled: true }]
			}),
			sample: this.fb.group({
				code: [{ value: "", disabled: true }, Validators.required],
				gatheringDate: [{ value: "", disabled: true }],
				gatheringTime: [{ value: "", disabled: true }]
			}),
			pj: this.fb.group({
				cnpj: [{ value: "", disabled: true }, Validators.required],
				legalName: [{ value: "", disabled: true }, Validators.required]
			}),
			_pcl: [""],
			_company: [""],
			status: [""]
		});

		this.getCompanies();
	}

	ngOnInit() {
		this.authUserType = this.authService.getUser().userType;

		this.createObservables();
	}

	createObservables() {
		this.filteredPJs = this.pjSearchbar.valueChanges.pipe(
			startWith(""),
			map((value: any) => (typeof value === "string" ? value : value.legalName)),
			map((name) => (name ? this.filterPJs(name) : this.pjs.slice()))
		);
	}

	getData() {
		this.loaderService.newLoader("Buscando Informações");
		this.gatheringService.getById(this.gatheringId).subscribe((res: any) => {
			this.loaderService.stopLoader();
			res.donor.birthDate = res.donor.birthDate ? moment(res.donor.birthDate).format("DD/MM/YYYY") : "";
			res.sample.gatheringDate = res.sample.gatheringDate ? moment(res.sample.gatheringDate).format("DD/MM/YYYY HH:mm:ss") : "";
			res.sample.gatheringTime = res.sample.gatheringDate ? moment(res.sample.gatheringDate).format("HH:mm:ss") : "";
			res.receivedAt = res.receivedAt ? moment(res.receivedAt).format("DD/MM/YYYY - h:mm:ss") : "";
			res.updatedAt = res.updatedAt ? moment(res.updatedAt).format("DD/MM/YYYY - h:mm:ss") : "";
			res.createdAt = res.createdAt ? moment(res.createdAt).format("DD/MM/YYYY - h:mm:ss") : "";
			this.gathering = res;

			/// seta os valores do formulário
			this.form.patchValue(res);

			if (res._pcl) {
				this.pjType = "pcl";
				this.cnpj.patchValue(res._pcl.cnpj);
				this.legalName.patchValue(res._pcl.legalName);
				this._pcl.patchValue(res._pcl._id);
			}
			if (res._company) {
				this.pjType = "company";
				this.cnpj.patchValue(res._company.cnpj);
				this.legalName.patchValue(res._company.legalName);
				this._company.patchValue(res._company._id);
			}
			this.pjSelected = this.legalName.value;
		});
	}

	getCompanies() {
		this.companyService.getCompanyPCL().subscribe((res: any) => {
			this.pjs = res.data;
		});
	}

	toggleEdit() {
		this.editMode = !this.editMode;

		if (this.editMode) {
			this.cpf.enable();
			this.passport.enable();
			this.name.enable();
			this.birthDate.enable();
			this.gender.enable();
			this.phone.enable();
			this.email.enable();
			this.gatheringDate.enable();
			this.gatheringTime.enable();
			this.pjSearchbar.patchValue("");
		} else {
			this.cpf.disable();
			this.passport.disable();
			this.name.disable();
			this.birthDate.disable();
			this.gender.disable();
			this.phone.disable();
			this.email.disable();
			this.gatheringDate.disable();
			this.gatheringTime.disable();
		}
	}

	cancel() {
		this.toggleEdit();
		this.getData();
	}

	save() {
		const formatedBirthDate = moment(this.birthDate.value, "DD/MM/YYYY").toDate();

		/// concatena o campo de horas junto com o de data
		const formatedGatheringDate = moment(this.gatheringDate.value.slice(0, 10) + " " + this.gatheringTime.value, "DD/MM/YYYY HH:mm:ss").toDate();
		console.log(formatedGatheringDate);

		const donor = {
			...this.donor.value,
			birthDate: formatedBirthDate
		};

		const sample = {
			...this.gathering.sample,
			gatheringDate: formatedGatheringDate
		};

		/// detleta o campo só de horas para não salvar no banco de dados
		delete this.form.value.gatheringTime;
		const newForm = { ...this.form.value };
		newForm.donor = donor;

		newForm.sample = sample;
		console.log(newForm.sample.gatheringDate);

		this.gatheringService.update(this.gatheringId, newForm).subscribe((res: any) => {
			if (res.success) {
				return this.cancel();
			}
			return alert("Erro ao editar, tente novamente mais tarde");
		});
	}

	displayFnPJ(pj?: any): string | undefined {
		return pj ? pj.legalName : undefined;
	}

	filterPJs(value: any) {
		value = value.replace(/\.|\-|\//g, "");

		return this.pjs.filter((pj) => pj.legalName.toLowerCase().indexOf(value.toLowerCase()) === 0 || pj.cnpj.toLowerCase().indexOf(value.toLowerCase()) === 0);
	}

	selectedPJ(e) {
		const selected = e.option.value;

		this._company.patchValue("");
		this._pcl.patchValue("");

		if (selected.type == "company") this._company.patchValue(selected._id);
		if (selected.type == "pcl") this._pcl.patchValue(selected._id);

		this.pjSelected = selected.legalName;
	}

	softDelete() {
		if (confirm("Tem certeza que deseja deletar?")) {
			this.loaderService.newLoader("Deletando amostra, aguarde...");
			this.walletService.unchargeGathering(this.gatheringId).subscribe((response: any) => {
				if (response.success) {
					this.gatheringService.update(this.gatheringId, { active: false }).subscribe((gatheringResponse: any) => {
						this.loaderService.stopLoader();
						this.router.navigate(["/coleta/listar"]);
					});
				}
			});
		}
	}

	acceptSample() {
		this.loaderService.newLoader();
		this.walletService.chargeGathering(this.gatheringId).subscribe((response: any) => {
			if (response.success) {
				this.gatheringService.update(this.gatheringId, { status: "Em análise" }).subscribe(() => {
					this.getData();
				});
			}
			this.loaderService.stopLoader();
		});
	}

	get pj() {
		return this.form.get("pj");
	}
	get cnpj() {
		return this.pj.get("cnpj");
	}
	get legalName() {
		return this.pj.get("legalName");
	}

	get donor() {
		return this.form.get("donor");
	}
	get name() {
		return this.donor.get("name");
	}
	get cpf() {
		return this.donor.get("cpf");
	}
	get passport() {
		return this.donor.get("passport");
	}
	get birthDate() {
		return this.donor.get("birthDate");
	}
	get gender() {
		return this.donor.get("gender");
	}
	get phone() {
		return this.donor.get("phone");
	}
	get email() {
		return this.donor.get("email");
	}

	get sample() {
		return this.form.get("sample");
	}
	get code() {
		return this.sample.get("code");
	}
	get gatheringDate() {
		return this.sample.get("gatheringDate");
	}
	get gatheringTime() {
		return this.sample.get("gatheringTime");
	}

	get status() {
		return this.form.get("status");
	}
	get _pcl() {
		return this.form.get("_pcl");
	}
	get _company() {
		return this.form.get("_company");
	}
}
