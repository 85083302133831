import { Component, OnInit, ViewChild } from "@angular/core";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { ExportXmlService } from "src/app/services/export-xml/export-xml.service";
import { MatTableDataSource, MatDialog, MatPaginator } from "@angular/material";
import { ExportXmlModalComponent } from "src/app/components/export/export-xml-modal/export-xml-modal.component";
import { saveAs } from "file-saver";
import * as moment from "node_modules/moment/moment";
import { FormControl, Validators } from "@angular/forms";

@Component({
	selector: "app-export-xml",
	templateUrl: "./export-xml.component.html",
	styleUrls: ["./export-xml.component.scss"]
})
export class ExportXmlComponent implements OnInit {
	columns = ["exportID", "quantity", "date", "action"];
	dataSource = new MatTableDataSource();
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	length = 0;

	constructor(private loaderService: LoaderService, private exportXmlService: ExportXmlService, private dialog: MatDialog) {}

	ngOnInit() {
		this.getData();
		this.createObservables();
	}

	createObservables() {
		this.paginator.page.subscribe(() => this.loadData());
	}

	export() {
		const exportModal = this.dialog.open(ExportXmlModalComponent);

		exportModal.afterClosed().subscribe((res) => {
			if (res) {
				this.loaderService.newLoader();
				this.exportXmlService.getExportXmlData(res).subscribe((response: any) => {
					const file = new Blob([response.xml], { type: "application/xml" });
					saveAs(file, `${response.ID}`);
					this.getData();
					this.loaderService.stopLoader();
				});
			}
		});
	}

	getData() {
		this.paginator.pageIndex = 0;
		this.loadData();
	}

	loadData() {
		const query = {
			page: this.paginator.pageIndex,
			limit: this.paginator.pageSize ? this.paginator.pageSize : 10
		};
		this.loaderService.newLoader();
		this.exportXmlService.getExportLog(query).subscribe((response: any) => {
			response.exports.forEach((element) => {
				element.createdAt = moment(element.createdAt).format("DD/MM/YYYY");
			});
			this.length = response.count;
			this.dataSource.data = response.exports;
			this.loaderService.stopLoader();
		});
	}

	/** *Exporta o arquivo XML */
	exportById(elem) {
		this.loaderService.newLoader();
		this.exportXmlService.getExportXmlDataById(elem._id).subscribe((response: any) => {
			const file = new Blob([response.xml], { type: "application/xml" });
			saveAs(file, `${elem.exportID}.xml`);
			this.loaderService.stopLoader();
		});
	}
}
