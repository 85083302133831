import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { GatheringService } from "src/app/services/gathering/gathering.service";
import { ActivatedRoute } from "@angular/router";
import { LoaderService } from "src/app/shared/loader/loader.service";
import * as moment from "node_modules/moment/moment";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material";
import { PassportModalComponent } from "src/app/components/sample/passport-modal/passport-modal.component";

@Component({
	selector: "app-report",
	templateUrl: "./report.component.html",
	styleUrls: ["./report.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class ReportComponent implements OnInit {
	id: string;
	language = "pt";
	gathering: any;
	loaded: boolean = false;
	result: string = "Aguardando resultado";
	@Input() sample: string;

	logged = false;
	qrCodeURL = "";

	constructor(
		private gatheringService: GatheringService,
		private loaderService: LoaderService,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private passportDialog: MatDialog
	) {}

	ngOnInit() {
		//Exemplo
		this.id = this.route.snapshot.paramMap.get("id") || this.sample;
		this.getReport();
		if (localStorage.user) this.logged = true;
	}

	getReport() {
		this.loaderService.newLoader("Buscando Informações");
		this.gatheringService.getById(this.id).subscribe((res: any) => {
			// this.qrCodeURL = `http://localhost:8100/resultado/${res.donor.cpf}/${res.sample.code}`;
			this.qrCodeURL = `https://resultado.semcovid.io/resultado/${res.donor.cpf}/${res.sample.code}`;

			this.gathering = res;
			this.gathering.sample.gatheringDate = moment(this.gathering.sample.gatheringDate).format("DD/MM/YYYY - HH:mm").replace("- 00:00", "");
			if (this.gathering.analysis) this.gathering.analysis.resultDate = moment(this.gathering.analysis.resultDate).format("DD/MM/YYYY - HH:mm").replace("- 00:00", "");
			this.gathering.donor.birthDate = this.gathering.donor.birthDate ? moment(this.gathering.donor.birthDate).utcOffset("+00:00").format("DD/MM/YYYY") : null;
			this.gathering.updatedAt = moment(this.gathering.updatedAt).format("DD/MM/YYYY");

			this.loaderService.stopLoader();
			this.loaded = true;
			this.log("acesso");
		});
	}

	print() {
		window.print();
		this.log("impressão");
	}

	changeLanguage() {
		if (this.language == "pt") {
			this.translate.use("en-us");
			this.language = "en";
		} else {
			this.translate.use("pt-br");
			this.language = "pt";
		}
	}

	addPassport() {
		const passportDialog = this.passportDialog.open(PassportModalComponent, { data: { _id: this.id } });
		passportDialog.afterClosed().subscribe(() => {
			this.getReport();
		});
	}

	log(type: string) {
		const log: any = { date: moment(), feature: "laudo", type: type };

		if (this.logged) {
			this.userLog(log);
		} else {
			log.visitor = this.gathering.donor.cpf;
			this.visitorLog(log);
		}
	}

	visitorLog(log: any) {
		this.gatheringService.newVisitorLog(this.id, log).subscribe();
	}

	userLog(log: any) {
		this.gatheringService.newLog(this.id, log).subscribe();
	}
}
