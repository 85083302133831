import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { FormControl } from "@angular/forms";
import { CompanyService } from "src/app/services/company/company.service";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";

@Component({
	selector: "app-company-list",
	templateUrl: "./list.component.html",
	styleUrls: ["./list.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class CompanyListComponent implements OnInit {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	columns = ["cnpj", "legalName", "email", "examPrice"];
	dataSource = new MatTableDataSource();
	filter: FormControl = new FormControl("");
	length = 0;

	constructor(private companyService: CompanyService, private loaderService: LoaderService) {}

	ngOnInit() {
		this.getData();
		this.createObservables();
	}

	createObservables() {
		this.filter.valueChanges
			.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				tap(() => this.getData())
			)
			.subscribe();
		this.paginator.page.subscribe(() => this.loadData());
	}

	getData() {
		this.paginator.pageIndex = 0;
		this.loadData();
	}

	loadData() {
		const query = {
			filter: this.filter.value,
			page: this.paginator.pageIndex,
			limit: this.paginator.pageSize ? this.paginator.pageSize : 10
		};

		this.loaderService.newLoader("Buscando Empresas");
		this.companyService.listCompanies(query).subscribe((response: any) => {
			this.length = response.count;
			this.dataSource.data = response.companies;
			this.loaderService.stopLoader();
		});
	}
}
