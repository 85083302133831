import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { API } from "../../app.api";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class PclService {
	constructor(public http: HttpClient) {}

	getAll(): any {
		return this.http.get(`${API}/pcl`);
	}

	create(body: any) {
		return this.http.post(`${API}/pcl`, body);
	}

	getByCnpj(cnpj: string) {
		return this.http.get(`${API}/pcl/cnpj`, {
			params: new HttpParams().set("cnpj", cnpj)
		});
	}

	// BUSCA COM PAGINAÇÃO E FILTROS
	listPCLs(filter: any) {
		return this.http.get(`${API}/pcl/list`, {
			params: new HttpParams().set("filter", filter.filter).set("page", filter.page).set("limit", filter.limit)
		});
	}

	update(_id, pcl) {
		return this.http.put(`${API}/pcl/${_id}`, { pcl });
	}
}
