import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { API } from "../../app.api";

@Injectable({
	providedIn: "root"
})
export class AuthService {
	constructor(private http: HttpClient) {}

	public isAuthenticated(): boolean {
		const token = localStorage.getItem("token");
		return token ? true : false;
	}

	public login(email: string, password: string): Observable<any> {
		return this.http.post<any>(`${API}/auth`, { email, password });
	}

	public registerToken(token: string) {
		localStorage.setItem("token", token);
	}

	public getToken() {
		return localStorage.getItem("token");
	}

	public registerUser(user: any) {
		localStorage.setItem("user", JSON.stringify(user));
	}

	public getUser() {
		return JSON.parse(localStorage.getItem("user"));
	}

	public logout() {
		localStorage.removeItem("token");
		localStorage.removeItem("user");
	}
}
