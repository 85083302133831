import { Component, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";
import { PermissionsService } from "../../services/permissions/permissions.service";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { UserService } from "src/app/services/user/user.service";
import { API } from "src/app/app.api";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent {
	user: FormGroup;
	private isResetPassword = false;

	constructor(
		private fb: FormBuilder,
		private loaderService: LoaderService,
		private router: Router,
		private authService: AuthService,
		private permissionsServ: PermissionsService,
		private userService: UserService
	) {
		if (this.authService.isAuthenticated()) {
			this.permissionsServ.loadPermissions();
			this.router.navigate(["home"]);
		}

		this.user = this.fb.group({
			email: ["", [Validators.required, Validators.email]],
			password: ["", Validators.required]
		});
	}

	login() {
		this.loaderService.newLoader();
		return this.authService.login(this.email.value, this.password.value).subscribe(
			({ accessToken, user }) => {
				this.authService.registerToken(accessToken);
				this.authService.registerUser(user);
				this.permissionsServ.loadPermissions();
				this.loaderService.stopLoader();
				this.router.navigate(["home"]);
			},
			({ error }) => {
				console.log(error);
				alert(error.message[0]);
				this.loaderService.stopLoader();
			}
		);
	}

	get email() {
		return this.user.get("email");
	}
	get password() {
		return this.user.get("password");
	}

	async forgotPassword() {
		try {
			await this.userService.forgotPassword({ email: this.email.value, api: API });
			alert("Pedido de recuperação enviado com sucesso.\nPor favor, verificar o seu e-mail.");
			this.setIsResetPassword(false);
		} catch (error) {
			alert(error);
		}
	}
	setIsResetPassword(value: boolean) {
		if (this.user.controls.email.status == "VALID") {
			this.isResetPassword = value;
		} else {
			alert("Por favor, escreva um e-mail válido");
		}
		return;
	}
}
