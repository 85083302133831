import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API } from "../../app.api";

@Injectable({
	providedIn: "root"
})
export class PdfService {
	constructor(private http: HttpClient) {}

	reportPDF(data: any) {
		return this.http.post(`${API}/pdf`, data);
	}
}
