import { Component, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtilsService } from "src/app/services/utils.service";
import { UserService } from "src/app/services/user/user.service";
import { Router } from "@angular/router";

@Component({
	selector: "app-user-register",
	templateUrl: "./register.component.html",
	styleUrls: ["./register.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class UserRegisterComponent {
	form: FormGroup;
	userTypes = [
		{
			name: "Sem covid",
			id: "semcovid"
		},
		{
			name: "Empresa",
			id: "company"
		},
		{
			name: "PCL",
			id: "pcl"
		}
	];

	constructor(private fb: FormBuilder, public utils: UtilsService, private userService: UserService, private router: Router) {
		this.form = this.fb.group(
			{
				email: ["", [Validators.required, Validators.email]],
				name: ["", Validators.required],
				userType: ["", Validators.required],
				password: ["", [Validators.required, Validators.minLength(6)]],
				confirmPassword: [null, Validators.required],
				contact: this.fb.group({
					phone: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(11)]]
				})
			},
			{ validator: utils.matchPassword("password", "confirmPassword") }
		);
	}

	save(form) {
		if (form.userType == "semcovid") form.role = "semcovidAdmin";
		if (form.userType == "pcl") form.role = "pclAdmin";
		if (form.userType == "company") form.role = "companyAdmin";

		return this.userService.create(form).subscribe(
			async (response: any) => {
				this.router.navigate(["usuario/listar"]);
			},
			({ error }) => {
				console.log(error);
				alert(error.message[0]);
			}
		);
	}

	get email() {
		return this.form.get("email");
	}
	get name() {
		return this.form.get("name");
	}
	get password() {
		return this.form.get("password");
	}
	get confirmPassword() {
		return this.form.get("confirmPassword");
	}
	get userType() {
		return this.form.get("userType");
	}
	get contact() {
		return this.form.get("contact");
	}
	get phone() {
		return this.contact.get("phone");
	}
}
