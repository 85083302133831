import { Component, ViewEncapsulation, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { UtilsService } from "src/app/services/utils.service";
import { GatheringService } from "src/app/services/gathering/gathering.service";
import { MatDialogRef, MatRadioChange, MAT_DIALOG_DATA } from "@angular/material";
import { CompanyService } from "src/app/services/company/company.service";
import * as moment from "node_modules/moment/moment";
import "node_modules/moment/locale/pt-br";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";

@Component({
	selector: "app-sample-register",
	templateUrl: "./register.component.html",
	styleUrls: ["./register.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class SampleRegisterComponent implements OnInit {
	cnpj;
	form: FormGroup;
	gatheringBy;
	filteredPJs: Observable<any[]>;
	pjSearchbar: FormControl = new FormControl();
	pjs = [];
	pjSelected = "";

	constructor(
		private companyService: CompanyService,
		private fb: FormBuilder,
		private gatheringServ: GatheringService,
		private loaderService: LoaderService,
		private utils: UtilsService,
		public dialogRef: MatDialogRef<SampleRegisterComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		const current_default_date = new Date();
		this.form = this.fb.group({
			// cnpj: ['',Validators.required],
			donor: this.fb.group({
				cpf: [""],
				// passport: ['', [Validators.minLength(8)]],
				name: ["", Validators.required],
				birthDate: [""],
				gender: [""],
				email: ["", [Validators.email]],
				phone: ["", [Validators.minLength(8), Validators.maxLength(11)]]
			}),
			sample: this.fb.group({
				gatheringDate: [moment(current_default_date).format("DD/MM/YYYY"), Validators.required],
				code: ["", [Validators.required]],
				insideCode: [true],
				gatheringTime: [moment(current_default_date).format("HH:mm:ss"), Validators.required]
				// serviceOrder: ['']
			}),
			_pcl: [null],
			_company: [null]
		});

		this.getCompanies();
	}

	ngOnInit() {
		const { code, insideCode } = this.data;
		this.code.setValue(code);
		this.insideCode.setValue(insideCode);
		this.createObservables();
	}

	createObservables() {
		this.filteredPJs = this.pjSearchbar.valueChanges.pipe(
			startWith(""),
			map((value: any) => (typeof value === "string" ? value : value.legalName)),
			map((name) => (name ? this.filterPJs(name) : this.pjs.slice()))
		);

		this.cpf.valueChanges.subscribe((value) => {
			if (value.length > 10) {
				this.loaderService.newLoader("Carregando dados");
				this.utils.getCPF(value).subscribe(
					(response: any) => {
						this.loaderService.stopLoader();
						this.name.setValue(response.nome);
						this.birthDate.setValue(response.nascimento);
					},
					() => this.loaderService.stopLoader()
				);
			}
		});
	}

	getCompanies() {
		this.companyService.getCompanyPCL().subscribe((res: any) => {
			this.pjs = res.data;
		});
	}

	displayFnPJ(pj?: any): string | undefined {
		return pj ? pj.legalName : undefined;
	}

	filterPJs(value: any) {
		value = value.replace(/\.|\-|\//g, "");

		return this.pjs.filter((pj) => pj.legalName.toLowerCase().indexOf(value.toLowerCase()) === 0 || pj.cnpj.toLowerCase().indexOf(value.toLowerCase()) === 0);
	}

	selectedPJ(e) {
		const selected = e.option.value;

		this._company.patchValue(null);
		this._pcl.patchValue(null);

		this.cnpj = selected.cnpj;
		this.gatheringBy = selected.type;
		if (selected.type == "company") this._company.patchValue(selected._id);
		if (selected.type == "pcl") this._pcl.patchValue(selected._id);

		this.pjSelected = selected.legalName;
	}

	async save(data) {
		// Deep Clone
		let gathering = JSON.parse(JSON.stringify(data));
		delete gathering.sample.gatheringTime;
		this.loaderService.newLoader();
		moment.locale("pt-BR");
		gathering.sample.gatheringDate = moment(data.sample.gatheringDate + " " + data.sample.gatheringTime, "DD/MM/YYYY HH:mm:ss").toDate();
		if (data.donor.birthDate) {
			gathering.donor.birthDate = moment(data.donor.birthDate, "DD/MM/YYYY").toDate();
		}
		const res = await this.gatheringServ.create(gathering).toPromise();
		this.loaderService.stopLoader();
		if (res.success) {
			const { gathering } = res;
			gathering[`_${this.gatheringBy}`] = { _id: gathering[`_${this.gatheringBy.value}`] };
			gathering[`_${this.gatheringBy}`].cnpj = this.cnpj;
			this.dialogRef.close(gathering);
		} else {
			alert(res.error);
		}
	}

	get _pcl() {
		return this.form.get("_pcl");
	}
	get _company() {
		return this.form.get("_company");
	}

	get donor() {
		return this.form.get("donor");
	}
	get name() {
		return this.donor.get("name");
	}
	get cpf() {
		return this.donor.get("cpf");
	}
	get birthDate() {
		return this.donor.get("birthDate");
	}
	get gender() {
		return this.donor.get("gender");
	}
	get email() {
		return this.donor.get("email");
	}
	get phone() {
		return this.donor.get("phone");
	}

	get sample() {
		return this.form.get("sample");
	}
	get code() {
		return this.sample.get("code");
	}
	get insideCode() {
		return this.sample.get("insideCode");
	}
	get gatheringDate() {
		return this.sample.get("gatheringDate");
	}
}
