import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { API } from "../../app.api";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class GatheringService {
	constructor(public http: HttpClient) {}

	// BUSCA SIMPLES COM FILTROS
	getAll(filter?) {
		return this.http.get(`${API}/gatherings`);
	}

	getForCsvPclAnoAtual() {
		return this.http.get(`${API}/scripts/get-samples-for-csv`);
	}

	getForCsvPcl(filter) {
		return this.http.get(`${API}/gatherings`, {
			params: new HttpParams()
				.set("filter", filter.filter || "")
				.set("status", filter.status || "")
				.set("pj", filter.pj || "")
				.set("receptionInitialDate", filter.receptionInitialDate || "")
				.set("receptionFinalDate", filter.receptionFinalDate || "")
				.set("birthDate", filter.birthDate || "")
				.set("gatheringInitialDate", filter.gatheringInitialDate || "")
				.set("gatheringFinalDate", filter.gatheringFinalDate || "")
				.set("resultInitialDate", filter.resultInitialDate || "")
				.set("resultFinalDate", filter.resultFinalDate || "")
				.set("_pcl", filter._pcl || "")
				.set("lean", "true")
		});
	}

	getForCsvCompany(filter) {
		return this.http.get(`${API}/gatherings`, {
			params: new HttpParams().set("_company", filter._company)
		});
	}

	// BUSCA POR ID
	getById(id) {
		return this.http.get(`${API}/gatherings/one`, {
			params: new HttpParams().set("_id", id)
		});
	}

	// BUSCA POR CÓDIGO
	getByCode(code: string) {
		return this.http.get(`${API}/gatherings/${code}`);
	}

	// BUSCA COM PAGINAÇÃO E FILTROS
	listGatherings(filter: any) {
		return this.http.get(`${API}/gatherings/list`, {
			params: new HttpParams()
				.set("filter", filter.filter)
				.set("status", filter.status)
				.set("pj", filter.pj)
				.set("receptionInitialDate", filter.receptionInitialDate)
				.set("receptionFinalDate", filter.receptionFinalDate)
				.set("birthDate", filter.birthDate)
				.set("gatheringInitialDate", filter.gatheringInitialDate)
				.set("gatheringFinalDate", filter.gatheringFinalDate)
				.set("resultInitialDate", filter.resultInitialDate)
				.set("resultFinalDate", filter.resultFinalDate)
				.set("limit", filter.limit)
				.set("page", filter.page)
		});
	}

	// CADASTRA UMA COLETA
	create(body: any): Observable<any> {
		return this.http.post(`${API}/gatherings`, body);
	}

	// BUSCA AMOSTRAS DEFEITUOSAS - COM FILTROS
	getDefectiveSamples() {
		return this.http.get(`${API}/gatherings/defective`);
	}

	// BUSCA AMOSTRAS DEFEITUOSAS COM PAGINAÇÃO E FILTROS
	listDefectiveSamples(filter: any) {
		return this.http.get(`${API}/gatherings/list`, {
			params: new HttpParams().set("page", filter.page).set("limit", filter.limit)
		});
	}

	// CADASTRA UMA AMOSTRA DEFEITUOSA
	postDefectiveSample(body: any) {
		return this.http.post(`${API}/gatherings/defective`, body);
	}

	// ATUALIZA UMA AMOSTRA COMO DEFEITUOSA
	refuseSample(_id: any, refuseReason) {
		return this.http.put(`${API}/gatherings/${_id}`, {
			status: "Rejeitado",
			receivedAt: new Date(),
			refuseReason
		});
	}

	generateCodeForOutsideGatherings() {
		return this.http.post(`${API}/gatherings/generate-code`, {});
	}

	getReportResult(query: any) {
		return this.http.get(`${API}/gatherings/report-result`, {
			params: new HttpParams().set("cpf", query.cpf).set("code", query.code)
		});
	}

	update(_id: any, body: any) {
		return this.http.put(`${API}/gatherings/${_id}`, body);
	}

	newLog(_id: any, body: any) {
		return this.http.put(`${API}/gatherings/log/${_id}`, body);
	}

	newVisitorLog(_id: any, body: any) {
		return this.http.put(`${API}/gatherings/visitor-log/${_id}`, body);
	}

	waitingPaymentRelease(_id) {
		return this.http.get(`${API}/scripts/waiting-payment/${_id}`);
	}

	waitingPaymentCompanyRelease(_id) {
		return this.http.get(`${API}/scripts/waiting-payment-company/${_id}`);
	}
}
