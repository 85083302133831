import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NgxPermissionsService } from "ngx-permissions";
import { AuthService } from "../auth/auth.service";

@Injectable({
	providedIn: "root"
})
export class PermissionsService {
	constructor(private authServ: AuthService, private http: HttpClient, private permissionsServ: NgxPermissionsService) {}

	async loadPermissions() {
		const user = await this.authServ.getUser();
		if (user.permissions) this.permissionsServ.loadPermissions(user.permissions);
	}
}
