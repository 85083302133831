import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { FormControl } from "@angular/forms";
import { UserService } from "src/app/services/user/user.service";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";

@Component({
	selector: "app-user-list",
	templateUrl: "./list.component.html",
	styleUrls: ["./list.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class UserListComponent implements OnInit {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	columns = ["email", "name", "legalName"];
	dataSource = new MatTableDataSource();
	filter: FormControl = new FormControl("");
	length = 0;

	constructor(private loaderService: LoaderService, private userService: UserService) {}

	ngOnInit() {
		this.getData();
		this.createObservables();
	}

	createObservables() {
		this.filter.valueChanges
			.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				tap(() => this.getData())
			)
			.subscribe();
		this.paginator.page.subscribe(() => this.loadData());
	}

	getData() {
		this.paginator.pageIndex = 0;
		this.loadData();
	}

	loadData() {
		const query = {
			filter: this.filter.value,
			page: this.paginator.pageIndex,
			limit: this.paginator.pageSize ? this.paginator.pageSize : 10
		};

		this.loaderService.newLoader("Buscando Usuários");
		this.userService.listUsers(query).subscribe((response: any) => {
			this.length = response.count;
			const data = response.users.map((user) => {
				user.pj = {};
				if (user._pcl) {
					user.pj.cnpj = user._pcl.cnpj;
					user.pj.legalName = user._pcl.legalName;
				} else if (user._company) {
					user.pj.cnpj = user._company.cnpj;
					user.pj.legalName = user._company.legalName;
				} else {
					user.pj.cnpj = "Sem covid";
					user.pj.legalName = "Sem covid";
				}

				return user;
			});

			this.dataSource.data = data;
			this.loaderService.stopLoader();
		});
	}
}
