import { Component, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
	selector: "app-main-header",
	templateUrl: "./main-header.component.html",
	styleUrls: ["./main-header.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class MainHeaderComponent {
	constructor(private authService: AuthService, private router: Router) {}

	openPage(url) {
		this.router.navigate([url]);
	}

	logout() {
		this.authService.logout();
		this.router.navigate(["/login"]);
	}
}
