import { Component, OnInit } from "@angular/core";
import { AnalysisService } from "src/app/services/analysis/analysis.service";
import { MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
import { LoaderService } from "src/app/shared/loader/loader.service";

@Component({
	selector: "app-lot",
	templateUrl: "./lot.component.html",
	styleUrls: ["./lot.component.scss"]
})
export class LotComponent implements OnInit {
	columns = ["name", "action"];
	dataSource = new MatTableDataSource();

	constructor(private analysisService: AnalysisService, private loaderService: LoaderService, private router: Router) {}

	ngOnInit() {
		this.getData();
	}

	async getData() {
		this.loaderService.newLoader();
		this.analysisService.getConferenceLots().subscribe((response: any) => {
			this.dataSource.data = response.lots;
			this.loaderService.stopLoader();
		});
	}

	openConferencePage(lot: string) {
		return this.router.navigate([`amostra/conferencia/${lot}`]);
	}
}
