import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "./services/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
	private jwtToken: any;
	constructor(private authService: AuthService, private router: Router, public translate: TranslateService, private route: ActivatedRoute) {
		translate.setDefaultLang("pt-br");
		translate.use("pt-br");
	}

	ngOnInit() {
		this.jwtToken = this.route.snapshot.paramMap.get("token") || "";
		const updated = localStorage.getItem("updated");

		if (!updated) {
			this.authService.logout();
			localStorage.setItem("updated", "true");
			this.router.navigate(["/login"]);
		}

		const location = window.location.href;
		if (location.includes("resultado.semcovid.io") || location.includes("resultado.semcovid.com.br")) {
			const path = location.split("/");
			if (path.length > 4) {
				this.router.navigate([`/resultado/${path[4]}/${path[5]}`]);
			} else {
				this.router.navigate(["/resultado"]);
			}
		}
	}

	showHeader() {
		this.jwtToken = this.route.snapshot.paramMap.get("token") || "";
		const routesWithoutHeader = ["/login", "/resultado"];
		if (this.router.url.search("/resetpassword/") == 0) {
			routesWithoutHeader.push(this.router.url);
		}
		return !routesWithoutHeader.includes(this.router.url);
	}

	showSideNav() {
		const routesWithoutSideNav = ["login", "resultado", "home", "resetpassword"];
		let path = this.router.url.split("?");
		path = path[0].split("/");
		return !routesWithoutSideNav.includes(path[1]);
	}
}
