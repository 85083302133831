import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatTableDataSource, MatPaginator, PageEvent } from "@angular/material";
import { ResultModalComponent } from "src/app/components/analysis/result-modal/result-modal.component";
import * as moment from "node_modules/moment/moment";
import { AnalysisService } from "src/app/services/analysis/analysis.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";
import { FormControl, FormBuilder, FormGroup } from "@angular/forms";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { CompanyService } from "src/app/services/company/company.service";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, tap, map, startWith } from "rxjs/operators";

@Component({
	selector: "app-sample-analysis",
	templateUrl: "./analysis.component.html",
	styleUrls: ["./analysis.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class SampleAnalysisComponent implements OnInit {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	columns = ["code", "status", "donor", "date", "receivedAt", "payment"];
	dataSource = new MatTableDataSource();
	displayFilter: boolean = false;
	filters: FormGroup;
	user: any = {};
	length = 0;
	filteredPJs: Observable<any[]>;
	pj: any;
	pjSearchbar: FormControl = new FormControl();
	pjs = [];

	constructor(
		private analysisService: AnalysisService,
		private authService: AuthService,
		private companyService: CompanyService,
		private dialog: MatDialog,
		private fb: FormBuilder,
		private loaderService: LoaderService,
		private router: Router
	) {
		this.filters = this.fb.group({
			filter: [""],
			status: [""]
		});

		this.getCompanies();
	}

	ngOnInit() {
		this.user = this.authService.getUser();
		this.getData();
		this.createObservables();
	}

	createObservables() {
		this.filter.valueChanges
			.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				tap(() => this.getData())
			)
			.subscribe();
		this.paginator.page.subscribe(() => this.loadData());

		this.filteredPJs = this.pjSearchbar.valueChanges.pipe(
			startWith(""),
			map((value: any) => (typeof value === "string" ? value : value.legalName)),
			map((name) => (name ? this.filterPJs(name) : this.pjs.slice()))
		);
	}

	getData() {
		this.paginator.pageIndex = 0;
		this.loadData();
	}

	loadData() {
		const query = {
			filter: this.filter.value,
			pj: this.pj ? this.pj._id : "",
			page: this.paginator.pageIndex,
			limit: this.paginator.pageSize ? this.paginator.pageSize : 10
		};

		this.loaderService.newLoader();
		this.analysisService.listAll(query).subscribe((response: any) => {
			this.loaderService.stopLoader();
			this.length = response.count;
			this.dataSource.data = response.gatherings.map((item) => {
				item.sample.gatheringDate = moment(item.sample.gatheringDate).format("DD/MM/YYYY");
				item.receivedAt = item.receivedAt ? moment(item.receivedAt).format("DD/MM/YYYY") : "Aguardando recebimento";
				item.payment.status = item.payment.balance == 0 ? "Pago" : "Pendente";
				return item;
			});
		});
	}

	openResultModal() {
		const resultModal = this.dialog.open(ResultModalComponent);

		resultModal.afterClosed().subscribe((res) => {
			if (res) {
				this.loaderService.newLoader();
				this.analysisService.sendGatheringAnalysis(res.gatherings, res.lot, res.typeOfMaterial).subscribe((result: any) => {
					this.loaderService.stopLoader();
					if (result.success) {
						if (result.errors.length > 0) {
							alert("Amostra(s) com erro: \n" + result.errors.join(" "));
						}

						if (this.user.permissions.includes("admin")) {
							return this.router.navigate([`amostra/conferencia/${res.lot}`]);
						}

						alert("Amostras enviadas para conferência.");
						return this.getData();
					}
				});
			}
		});
	}

	getCompanies() {
		this.companyService.getCompanyPCL().subscribe((res: any) => {
			this.pjs = res.data;
		});
	}

	toggleFilter() {
		this.displayFilter = !this.displayFilter;
	}

	displayFnPJ(pj?: any): string | undefined {
		return pj ? pj.legalName : undefined;
	}

	filterPJs(value: any) {
		value = value.replace(/\.|\-|\//g, "");
		return this.pjs.filter((pj) => pj.legalName.toLowerCase().indexOf(value.toLowerCase()) === 0 || pj.cnpj.toLowerCase().indexOf(value.toLowerCase()) === 0);
	}

	selectedPJ(e) {
		this.pj = e.option.value;
		this.getData();
	}

	get filter() {
		return this.filters.get("filter");
	}
}
