import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PCLRegisterComponent } from "./pages/pcl/register/register.component";
import { LoginComponent } from "./pages/login/login.component";
import { GatheringRegisterComponent } from "./pages/gathering/register/register.component";
import { GatheringListComponent } from "./pages/gathering/list/list.component";
import { HomeComponent } from "./pages/home/home.component";
import { PCLListComponent } from "./pages/pcl/list/list.component";
import { AuthGuardService as AuthGuard } from "./services/auth/auth-guard.service";
import { CompanyRegisterComponent } from "./pages/company/register/register.component";
import { CompanyListComponent } from "./pages/company/list/list.component";
import { SampleReceptionComponent } from "./pages/sample/reception/reception.component";
import { SampleAnalysisComponent } from "./pages/sample/analysis/analysis.component";
import { FinancialPaymentsComponent } from "./pages/financial/payments/payments.component";
import { UserRegisterComponent } from "./pages/user/register/register.component";
import { UserListComponent } from "./pages/user/list/list.component";
import { FinancialPCLsComponent } from "./pages/financial/pcls/pcls.component";
import { FinancialCompaniesComponent } from "./pages/financial/companies/companies.component";
import { ReportComponent } from "./pages/report/report.component";
import { ConferenceComponent } from "./pages/sample/conference/conference.component";
import { LotComponent } from "./pages/sample/lot/lot.component";
import { NgxPermissionsGuard } from "ngx-permissions";
import { ReportResultComponent } from "./pages/result/report-result/report-result.component";
import { SampleEditComponent } from "./pages/sample/edit/edit.component";
import { PatientFormComponent } from "./pages/patient-form/patient-form.component";
import { ExportXmlComponent } from "./pages/export-xml/export-xml.component";
import { ResetPasswordComponent } from "./pages/resetPassword/resetPassword.component";
//aqui
const routes: Routes = [
	{
		path: "",
		redirectTo: "/login",
		pathMatch: "full"
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "login",
		component: LoginComponent
	},
	{
		path: "resetpassword/:token",
		component: ResetPasswordComponent
	},
	{
		path: "resultado",
		component: ReportResultComponent
	},
	{
		path: "resultado/:cpf/:code",
		component: ReportResultComponent
	},
	{
		path: "pcl/cadastrar",
		component: PCLRegisterComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				only: ["admin", "credenciamento", "pcl.post"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "pcl/listar",
		component: PCLListComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "coleta/cadastrar",
		component: GatheringRegisterComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				except: ["credenciamento"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "coleta/listar",
		component: GatheringListComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				except: ["credenciamento"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "amostra/editar/:id",
		component: SampleEditComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				except: ["credenciamento"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "empresa/cadastrar",
		component: CompanyRegisterComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				only: ["admin", "credenciamento", "company.post"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "empresa/listar",
		component: CompanyListComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "amostra/analise",
		component: SampleAnalysisComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				except: ["credenciamento"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "amostra/recebimento",
		component: SampleReceptionComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				except: ["credenciamento"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "amostra/lotes",
		component: LotComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				only: ["admin", "lot.get"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "amostra/conferencia/:lot",
		component: ConferenceComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				only: ["admin", "lot.post"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "financeiro/pagamentos",
		component: FinancialPaymentsComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				except: ["credenciamento"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "financeiro/empresas",
		component: FinancialCompaniesComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				except: ["credenciamento"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "financeiro/pcls",
		component: FinancialPCLsComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				except: ["credenciamento"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "usuario/cadastrar",
		component: UserRegisterComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				only: ["admin", "user.post"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "usuario/listar",
		component: UserListComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				only: ["admin", "user.get"],
				redirectTo: "/home"
			}
		}
	},
	{
		path: "laudo/:id",
		component: ReportComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "paciente/:id",
		component: PatientFormComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "exportar-resultados",
		component: ExportXmlComponent,
		canActivate: [AuthGuard]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
