import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { API } from "src/app/app.api";

@Injectable({
	providedIn: "root"
})
export class AnalysisService {
	constructor(private http: HttpClient) {}

	getAll(filter) {
		return this.http.get(`${API}/gatherings`, {
			params: new HttpParams().set("status", "Em análise")
		});
	}

	listAll(filter) {
		return this.http.get(`${API}/gatherings/list`, {
			params: new HttpParams().set("status", "Em análise").set("filter", filter.filter).set("pj", filter.pj).set("page", filter.page).set("limit", filter.limit)
		});
	}

	sendGatheringAnalysis(gatherings, lot, typeOfMaterial) {
		return this.http.post(`${API}/analysis`, { gatherings, lot, typeOfMaterial });
	}

	getAllInConference(lot: string) {
		return this.http.get(`${API}/analysis/lot/${lot}`);
	}

	getConferenceLots() {
		return this.http.get(`${API}/analysis/lot`);
	}

	saveConverence(samples) {
		return this.http.post(`${API}/analysis/lot`, samples);
	}

	returnToAnalysis(samples) {
		return this.http.post(`${API}/analysis/lot/back`, samples);
	}
}
