import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API } from "../../app.api";

@Injectable({
	providedIn: "root"
})
export class BarcodeService {
	constructor(private http: HttpClient) {}

	newCode(code) {
		return this.http.get(`${API}/barcode/${code}`);
	}
}
